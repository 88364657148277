import React, {useContext, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useHistory} from 'react-router-dom';
import {getNotifications, hasUnreadNotifications, markNotificationAsRead} from "../../../../../services/push.service";
import {Notification} from "../../../../../models/notification";
import {useIonViewWillEnter, IonSkeletonText, IonItem, IonAvatar, IonLabel, IonList} from "@ionic/react";
import AvatarImage from '../../../../../assets/images/avatar-blue.svg';
import Pane from "../../../../../components/pane/pane.component";
import {StyledNotification} from "../notificationsTab.style";
import {NotificationAction} from "../../../../../enums/notificationAction";
import {Links} from "../../../../links";
import PreviewModal from "../modals/previewModal.component";
import {MainPageContext} from "../../../mainPage.component";
import CircleImage, {ImageTypes} from "../../../../../components/circleImage/circleImage.component";
import {StyledEmptyContainer} from "../../../../../components/content/content.style";
import useNavigation from "../../../../../services/navigation.service";
import {SubNavigationContext} from "../../../../../components/sub-navigation/subNavigation.component";
import {NotificationsTabOptions} from "../notificationsTab.component";
import {log} from "../../../../../services/firebaseAnalytics.service";
import {Browser} from "@capacitor/browser";
import TruncateText from "../../../../../components/truncateText/truncateText.component";

type NotificationsPaneProps = {
    topEdge?: number;
}

const NotificationsPane: React.FC<NotificationsPaneProps> = (props: NotificationsPaneProps) => {
    const {t} = useTranslation();
    const history = useHistory();
    const navigation = useNavigation(history);
    const mainPageManager = useContext(MainPageContext);

    const [notifications, setNotifications] = useState<[] | undefined>(undefined);
    const [selectedNotification, setSelectedNotification] = useState<Notification | null>(null);
    const [loading, setLoading] = useState<boolean>(false);
    const fetchNotifications = async () => {
        if (notifications === undefined) {
            setLoading(true);
            setNotifications([]);
            const response = await getNotifications()
                .then(response => {
                    setNotifications(response.data);
                });

            setLoading(false);
        }
    };

    useEffect(() => {
        fetchNotifications();
    })

    useIonViewWillEnter(() => {
        fetchNotifications();
    });

    const getButtonDescription = (notification: Notification) => {
        switch(notification.notification.redirect_to) {
            case NotificationAction.PROFILE:
                return t("notificationTab.confirmButton");
            case NotificationAction.CONTRACTS:
                return t("notificationTab.gotoButton");
            case NotificationAction.CONTRACT:
            case NotificationAction.CONTRACT_APPENDIX:
                return t("notificationTab.gotoButton");
            case NotificationAction.CONTRACT_FILE:
                return t("notificationTab.gotoButton");
			case NotificationAction.TIMETABLE:
				return t("notificationTab.gotoButton");
			case NotificationAction.URL:
				return t("notificationTab.gotoButton");
            case NotificationAction.WAREHOUSE:
				return t("notificationTab.gotoButton");
        }
    };

    const gotoAction = async (notification: Notification) => {
        if (notification.status === 'sent') {
            await markNotificationAsRead(notification.id);
        }
        notification.status = 'read';

        let unread = await hasUnreadNotifications();
        mainPageManager.setNotificationBadge(unread);
        setSelectedNotification(null);
        switch (notification.notification.redirect_to) {
            case NotificationAction.CONTRACTS:
                history.push(Links.main + Links.contracts)
                break;
            case NotificationAction.CONTRACT:
            case NotificationAction.CONTRACT_APPENDIX:
                history.push(Links.main + Links.contracts)
                // history.push(Links.contract + '/' + notification.notification.redirect_to_parameter)
                break;
            case NotificationAction.CONTRACT_FILE:
                history.push(Links.contract + '/' + notification.notification.redirect_to_parameter + '?selectedOption=1')
                break;
            case NotificationAction.PROFILE:
                history.push(Links.main + Links.profile)
                break;
            case NotificationAction.WAREHOUSE:
                history.push(Links.main + Links.workerWarehouse)
                break;
			case NotificationAction.TIMETABLE:
				history.push(Links.main + Links.timetable + '?optionAvailability=0&notification=' + notification.notification.redirect_to_parameter)
				break;
            case NotificationAction.URL:
                if (notification.notification.redirect_to_parameter) {
                    await Browser.open({ url: notification.notification.redirect_to_parameter });
                }
                break;
        }
    }

    const openPreview = async (notification: Notification) => {
        if (notification.status === 'sent') {
            await markNotificationAsRead(notification.id);
        }
        notification.status = 'read';
        setSelectedNotification(notification);

        let unread = await hasUnreadNotifications();
        mainPageManager.setNotificationBadge(unread);
    }

	useEffect(() => {
		log('page_visit', {
			page: 'Powiadomienia -> Notyfikacje'
		});
	}, []);

    return (
        <Pane topEdge={props.topEdge} marginTop={40} paddingBottom={287}>
            {
                !loading && notifications && notifications.map((notification: Notification, i) =>
                    <StyledNotification key={i}>
                        <div className={'avatar ' + (notification.status === 'sent' ? 'active' : '')}>
                            { notification.notification.author?.photos.thumbnail ? <img src={notification.notification.author.photos.thumbnail} /> : <img src={AvatarImage} />}
                        </div>
                        <div className="content" onClick={event => openPreview(notification)}>
                            <div className="title">
                                {/*{*/}
                                {/*    notification.notification.redirect_to && getButtonDescription(notification) &&*/}
                                {/*    <button className="action-button" onClick={event => {*/}
                                {/*        event.stopPropagation();*/}
                                {/*        gotoAction(notification)*/}
                                {/*    }}>{getButtonDescription(notification)}</button>*/}
                                {/*}*/}
                                {notification.notification.subject}
                            </div>
							<div className="description">
								{ notification.notification.sent_date}
							</div>
                            <div className="description">
                                <TruncateText lines={2}>{notification.notification.body}</TruncateText>
                            </div>
                        </div>
                    </StyledNotification>
                )
            }

            {
                !loading && notifications && notifications.length === 0 &&
                <StyledEmptyContainer>
                    <CircleImage image={ImageTypes.NOTE} color="grey"/>
                    <h3>{t('notificationTab.empty')}</h3>
                </StyledEmptyContainer>
            }

            {
                loading &&
                <IonList>
                    <IonItem>
                        <IonAvatar slot="start">
                            <IonSkeletonText animated />
                        </IonAvatar>
                        <IonLabel>
                            <h3>
                                <IonSkeletonText animated style={{ width: '50%' }} />
                            </h3>
                            <p>
                                <IonSkeletonText animated style={{ width: '80%' }} />
                            </p>
                            <p>
                                <IonSkeletonText animated style={{ width: '60%' }} />
                            </p>
                        </IonLabel>
                    </IonItem>
                    <IonItem>
                        <IonAvatar slot="start">
                            <IonSkeletonText animated />
                        </IonAvatar>
                        <IonLabel>
                            <h3>
                                <IonSkeletonText animated style={{ width: '50%' }} />
                            </h3>
                            <p>
                                <IonSkeletonText animated style={{ width: '80%' }} />
                            </p>
                            <p>
                                <IonSkeletonText animated style={{ width: '60%' }} />
                            </p>
                        </IonLabel>
                    </IonItem>
                    <IonItem>
                        <IonAvatar slot="start">
                            <IonSkeletonText animated />
                        </IonAvatar>
                        <IonLabel>
                            <h3>
                                <IonSkeletonText animated style={{ width: '50%' }} />
                            </h3>
                            <p>
                                <IonSkeletonText animated style={{ width: '80%' }} />
                            </p>
                            <p>
                                <IonSkeletonText animated style={{ width: '60%' }} />
                            </p>
                        </IonLabel>
                    </IonItem>
                    <IonItem>
                        <IonAvatar slot="start">
                            <IonSkeletonText animated />
                        </IonAvatar>
                        <IonLabel>
                            <h3>
                                <IonSkeletonText animated style={{ width: '50%' }} />
                            </h3>
                            <p>
                                <IonSkeletonText animated style={{ width: '80%' }} />
                            </p>
                            <p>
                                <IonSkeletonText animated style={{ width: '60%' }} />
                            </p>
                        </IonLabel>
                    </IonItem>
                    <IonItem>
                        <IonAvatar slot="start">
                            <IonSkeletonText animated />
                        </IonAvatar>
                        <IonLabel>
                            <h3>
                                <IonSkeletonText animated style={{ width: '50%' }} />
                            </h3>
                            <p>
                                <IonSkeletonText animated style={{ width: '80%' }} />
                            </p>
                            <p>
                                <IonSkeletonText animated style={{ width: '60%' }} />
                            </p>
                        </IonLabel>
                    </IonItem>
                    <IonItem>
                        <IonAvatar slot="start">
                            <IonSkeletonText animated />
                        </IonAvatar>
                        <IonLabel>
                            <h3>
                                <IonSkeletonText animated style={{ width: '50%' }} />
                            </h3>
                            <p>
                                <IonSkeletonText animated style={{ width: '80%' }} />
                            </p>
                            <p>
                                <IonSkeletonText animated style={{ width: '60%' }} />
                            </p>
                        </IonLabel>
                    </IonItem>
                </IonList>
            }
            <PreviewModal
                isOpen={selectedNotification !== null}
                onClose={() => setSelectedNotification(null)}
                notification={selectedNotification}
                buttonText={selectedNotification ? getButtonDescription(selectedNotification) : undefined}
                onAction={() => selectedNotification ? gotoAction(selectedNotification) : null}>
            </PreviewModal>
        </Pane>
    );
};

export default NotificationsPane;
