import React, {useEffect, useRef, useState} from 'react';
import {useTranslation} from "react-i18next";
import {StyledInput} from '../../components/form/input/input.style';
import {StyledConversationContainer, StyledConversationMessages, StyledNewMessage} from './conversation.style';
import SendImage from '../../assets/images/send.svg'
import AvatarImage from '../../assets/images/avatar.svg'
import {StyledIonModal} from '../../components/modal/modal.style';
import {StyledPaneBar, StylePaneGrabber} from '../../components/pane/pane.style';
import {IonContent} from '@ionic/react';
import ModalService from '../../services/modal.service';
import {ContractComment} from "../../models/contractComment";
import Form from "../../components/form";
import {useForm} from "react-hook-form";
import nl2br from "react-nl2br";
import {Preferences} from "@capacitor/preferences";

type ConversationModalProps = {
    comments: ContractComment[] | undefined;
    isOpen: boolean;
    onClose: () => void;
    onSave: (remarks: string) => void;
};

export interface IConversationModalFormData {
    comment: string;
}

const ConversationModal: React.FC<ConversationModalProps> = (props: ConversationModalProps) => {
    const {t} = useTranslation();
    const [showConversation, updateShowConversation] = useState<boolean>(false);
    const [avatarUrl, setAvatarUrl] = useState<any>();

    const {register, handleSubmit, formState: {isValid}, setValue} = useForm({
        mode: 'onChange'
    });

    useEffect(() => {
        handleOpen();
    });

    const getAvatarUrl = async () => {
        let avatarUrl = await Preferences.get({'key': 'avatar_url'});
        setAvatarUrl(avatarUrl.value);
    };

    useEffect(() => {
        getAvatarUrl();
    }, []);

    const messagesContainerRef = useRef<HTMLIonContentElement>(null);
    const handleOpen = () => {
        updateShowConversation(props.isOpen);
    };

    const handleCloseConversation = () => {
        props.onClose();
    };

    const onSubmit = (data: IConversationModalFormData) => {
        const onSave = props.onSave;
        if (onSave) {
            onSave(data.comment);
            setValue('comment', '');
        }
    };

    useEffect(() => {
        if (showConversation) {
            ModalService.modalOpened(props.onClose);
            setTimeout(
                () => {
                    console.log(messagesContainerRef?.current?.clientHeight);

                    if (messagesContainerRef?.current) {
                        messagesContainerRef.current.scrollToBottom();
                    }
                }
            );
        } else {
            ModalService.modalDismissed();
        }
    }, [showConversation])

    return (
        <StyledIonModal isOpen={showConversation} onDidDismiss={() => handleCloseConversation()}>
            <StylePaneGrabber>
                <StyledPaneBar></StyledPaneBar>
            </StylePaneGrabber>

            <IonContent className="ion-content-conversation" id="ion-content-conversation" ref={messagesContainerRef}>
                <StyledConversationContainer>
                    <StyledConversationMessages >
                        {props.comments?.map((comment: ContractComment, key) => {
                            return <div className={comment.is_worker_author ? "message" : "message own"} key={key}>
                                {!comment.is_worker_author &&
                                <img src={avatarUrl || AvatarImage}/>}{nl2br(comment.comment)}
                            </div>
                        })}
                    </StyledConversationMessages>
                </StyledConversationContainer>
            </IonContent>
            <Form.Container onSubmit={handleSubmit(onSubmit)}>
                <StyledNewMessage>
                    <StyledInput placeholder={t("conversation.addNewMessage")}
                                 ref={register({required: true})}
                                 name={"comment"} type="text"/>
                    <button className="add-button" type={"submit"} disabled={!isValid}>
                        <img src={SendImage}/>
                    </button>
                </StyledNewMessage>
            </Form.Container>
        </StyledIonModal>
    );
};

export default ConversationModal;
