import styled from 'styled-components';

export const StyledLogoContainer = styled.div`
  display: inline-block;
  text-align: center;
  width: 100%;
`;

export const StyledLogoImage = styled.img`
  display: inline-block;
  height: 78.82px;
  width: 72.1px;
`;
