import { IonCol, IonGrid, IonRow } from '@ionic/react';
import React, { useEffect, useState } from 'react';
import {useTranslation} from 'react-i18next';
import Pane from "../../../../../components/pane/pane.component";
import { StyledOffer } from '../offersTab.style';
import ArrowRightImage from '../../../../../assets/images/arrow-right.svg';
import PinImage from '../../../../../assets/images/pin-grey.svg';
import TimeClockImage from '../../../../../assets/images/time-clock.svg';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import { Links } from '../../../../links';
import {JobOffer} from "../../../../../models/jobOffer";
import AasLogoImage from "../../../../../assets/images/aas-logo.svg";
import ExactLogoImage from "../../../../../assets/images/exact-logo.svg";

type OffersPaneProps = {
    topEdge?: number;
    offers: JobOffer[];
}

const OffersPane: React.FC<OffersPaneProps> = (props: OffersPaneProps) => {
    const {t} = useTranslation();
    const history = useHistory();
    const [offers, setOffers] = useState<JobOffer[]>([]);

    useEffect(() => {
        setOffers(props.offers)
    }, props.offers);

    const getDateColor = (date: Date): string => {
        const diff = moment(date).diff(Date.now(), 'days');

        if (diff > 4) {
            return 'green';
        } else if (diff > 1) {
            return 'orange';
        } else {
            return 'red';
        }
    }

    return (
        <Pane topEdge={props.topEdge} marginTop={40} paddingBottom={247}>
            {offers.map((offer, key) =>
                <StyledOffer key={key}>
                    <IonGrid>
                        <IonRow onClick={() => history.push(Links.offer + `/${offer.id}`)}>
                            <IonCol size="10.5">
                                <div className="title">{offer.title}</div>
                                <div className="details">
                                    {offer?.contract_type &&
                                        <><span>{t(offer?.contract_type)}</span><br /><br /></>
                                    }
                                    <img src={PinImage} />{offer.location}
                                </div>
                                <div className="company">
                                    <img src={offer.company_code.includes('AAS') ? AasLogoImage : ExactLogoImage}/>{offer.company}
                                </div>
                            </IonCol>
                            <IonCol size="1.5" className="arrow">
                                <img src={ArrowRightImage} />
                            </IonCol>
                        </IonRow>
                    </IonGrid>
                </StyledOffer>
            )}
        </Pane>
    );
};

export default OffersPane;
