import styled from 'styled-components';
import {ContractAttachmentFolderStatus} from "../../../../enums/contractAttachmentFolderStatus";
import {ContractAttachmentFolder} from "../../../../models/contractAttachmentFolder";

export const StyledAttachementFolderContainer = styled.div`
  width:  100%;
  background: #FFFFFF;
  border: 1px solid #EDEDF3;
  box-sizing: border-box;
  box-shadow: 0px 1px 1px rgba(136, 138, 152, 0.25);
  border-radius: 16px;
  display: flex;
  flex-direction: row;
  padding: 18px;
  margin-top: 8px;

  img.open {
    margin-top: 6px;
    width: 12px;
    height: 12px;
  }
`;

export const StyledAttachementFolderContent = styled.div`
  flex-grow: 1;

  .title {
    font-family: Inter;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    color: #000000;
    margin: 0px 4px;
  }

  small {
    display: flex;
    align-items: center;
    font-family: Inter;
    font-style: normal;
    font-size: 12px;
    line-height: 140%;
    color: #888A98;

    img {
      width: 10px;
      height: 10px;
      margin-right: 8px;
    }

    .validity {  
      margin-left: 4px;    
      color: #14CC52;
      font-weight: 600;
    }
  }
`;

export type StyledAttachementFolderProps = {
    folder: ContractAttachmentFolder | undefined;
}

export const StyledAttachementFolderStatus = styled.div<StyledAttachementFolderProps>`
  width: 24px;
  height: 24px;
  border-radius: 12px;
  background-color: ${(props) => getBackgroundColor(props.folder)};
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 13px;

  img {
    width: 10px;
    height: 10px;
  }
`;

function getBackgroundColor(folder: ContractAttachmentFolder | undefined): string {
    if (folder && folder.expiration_date && folder.qualification_date) {
        return '#14CC52';
    } else if (folder && !folder.qualification_date && !folder.expiration_date) {
        return '#F43030';
    } else {
        return '#FFAC00';
    }
}