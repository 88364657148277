import React, {useState} from 'react';
import {
    StyledAttachementFolderContainer,
    StyledAttachementFolderContent,
    StyledAttachementFolderStatus
} from './attachementFolder.style';
import ArrowRightImage from '../../../../assets/images/arrow-right.svg';
import ClockImage from '../../../../assets/images/time-clock.svg';
import OkImage from '../../../../assets/images/check.svg';
import AddImage from '../../../../assets/images/e-add.svg';
import RefreshImage from '../../../../assets/images/refresh-02.svg';
import RefreshGreyImage from '../../../../assets/images/refresh-01.svg';
import {Contract as ContractModel} from "../../../../models/contract";
import {ContractAttachmentFolder} from "../../../../models/contractAttachmentFolder";
import {ContractAttachmentFolderStatus} from "../../../../enums/contractAttachmentFolderStatus";
import {useTranslation} from "react-i18next";
import moment from "moment";
import TruncateText from "../../../../components/truncateText/truncateText.component";

type AttachementFolderProps = {
    onClick: () => void;
    contract?: ContractModel;
    folder?: ContractAttachmentFolder;
}

const AttachementFolder: React.FC<AttachementFolderProps> = (props: AttachementFolderProps) => {
    const {t} = useTranslation();

    return (
        <StyledAttachementFolderContainer onClick={() => props.onClick()}>
            <StyledAttachementFolderStatus folder={props.folder}>
                {props.folder?.expiration_date && props.folder?.qualification_date && <img src={OkImage}/>}
                {!props.folder?.expiration_date && !props.folder?.qualification_date && <img src={AddImage}/>}
                {props.folder?.expiration_date && !props.folder?.qualification_date && <img src={RefreshImage}/>}
            </StyledAttachementFolderStatus>
            <StyledAttachementFolderContent>
                <div className="title">
                    <TruncateText width={250}>{props.folder?.name}</TruncateText>
                </div>
                <small>
                    {props.folder?.expiration_date && props.folder?.qualification_date && <img src={ClockImage}/>}
                    {(!props.folder?.expiration_date || !props.folder?.qualification_date) && <img src={RefreshGreyImage}/>}
                    {props.folder?.qualification_date &&
                    <span>{t('contractPage.attachements.documentValidTill')} <span
                        className="validity">{moment(props.folder?.qualification_date).format('DD.MM.YYYY')}</span></span>}
                    {!props.folder?.expiration_date && !props.folder?.qualification_date && props.folder?.files && props.folder?.files.length > 0 &&
                    <span>{t('contractPage.attachements.documentInVerification')}</span>}
                    {!props.folder?.expiration_date && !props.folder?.qualification_date && props.folder?.files && props.folder?.files.length === 0 &&
                    <span>{t('contractPage.attachements.documentEmpty')}</span>}
                    {props.folder?.expiration_date && !props.folder?.qualification_date &&
                    <span>{t('contractPage.attachements.documentVerified')}</span>}
                </small>
                <small>
                    {props.folder?.files.length} {t('contractPage.attachements.filesCount')}
                </small>
            </StyledAttachementFolderContent>
            <img className="open" src={ArrowRightImage}/>
        </StyledAttachementFolderContainer>
    );
};

export default AttachementFolder;