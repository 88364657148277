import {IonModal} from '@ionic/react';
import styled from 'styled-components';

type StyledIonModalProps = {
    maxHeight?: string;
}

export const StyledIonModal = styled(IonModal)<StyledIonModalProps>`
    --border-radius: 16px;
    align-items: flex-end;
    --min-width: 100%;
    --width: 100%;
    --max-width: 100%;

    .modalForm {
      height: 100%;

      .label {
        margin-bottom: 8px;
        font-weight: 500;
        font-size: 12px;
        line-height: 15px;
        color: #000000;
      }
    }
    
    .modal-wrap-with-max {
        --max-height: ${(props) => props.maxHeight ? props.maxHeight : 'calc(100% - 40px)'};
        height: ${(props) => props.maxHeight ? props.maxHeight : 'calc(100% - 40px)'};
    }

    .modal-wrap {
        --max-height: ${(props) => props.maxHeight ? props.maxHeight : 'calc(100% - 40px)'};
        padding: 16px;
        position: relative;
        height: 100%;
        justify-content: start;
        
        img.close {
          position: absolute;
          top: 16px;
          right: 16px;
        }

        h1.title {
          font-weight: 600;
          font-size: 18px;
          line-height: 140%;
          color: #000000;
          margin-bottom: 20px;
          display: inline-block;
          width: 60%;
          
          &.full {
            width: 90%;
          }
        }

        h1.center {
          width: 100%;
          text-align: center;
        }
        
        &.filters {
            .clear-row ion-col {
                padding-bottom: 20px;
            }
            
            .header {
                font-weight: 600;
                font-size: 18px;
                line-height: 22px;
                color: #000000;
            }
        
            .clear-button {
                border: 1px solid #EDEDF3;
                border-radius: 39px;
                width: 100%;
                height: 100%;
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: space-between;
                padding: 8.5px 12px;
        
                font-weight: normal;
                font-size: 11px;
                line-height: 140%;
                color: #000000;
        
                span {
                    text-align: right;
                }
            }
        }
    }
`;

export const QrCodeModal = styled.div `
    background: transparent !important;
`

export const StyledModalContent = styled.div`
    display: grid;
    width: 100%;
    height: 100%;
    grid-template-columns: 100%;
    grid-template-rows: fit-content(90px) auto 100px;

    &.grabber {
      grid-template-rows: 40px auto 100px;
    }
    
    &.t-p-25 {
        padding-top: 25px !important;
    }
        
    &.t-p-50 {
        padding-top: 50px !important;
    }

    &.hidden {
      overflow: hidden;
    }

	&.scrollable {
      overflow: auto;
    }

	.data-row {
		padding: 2px 0;
	}

    .title {
      grid-column-start: 1;
      grid-column-end: 1;
      grid-row-start: 1;
      grid-row-end: 1;
    }

    .content {
      overflow: auto;

      grid-column-start: 1;
      grid-column-end: 1;
      grid-row-start: 2;
      grid-row-end: 2;
      padding: 5px;

      &.full-height {
        grid-row-start: 2;
        grid-row-end: 4;
      }
    }

	&.notification {
	  .title {
	  	width: 95% !important;
	  }

	  .date {
		grid-column-start: 1;
      	grid-column-end: 1;
      	grid-row-start: 2;
      	grid-row-end: 2;

	  	font-size: 14px;
	  	color: #888A98;
	  }

	  .content {
		margin-top: 30px;
	  }
	}

    .content.center {
      text-align: center;
    }

    .search-city {
        padding-bottom: 9px;
        font-size: 13px;
    }
    
    .footer {
      position: unset !important;
      grid-column-start: 1;
      grid-column-end: 1;
      grid-row-start: 3;
      grid-row-end: 3;
    }

    .content ol li {
      padding-bottom: 10px;
    }

    .content ol li ul {
      padding-top: 10px;
    }
`;

export const StyledActionIonModal = styled(IonModal)`
  --min-width: 100%;
  --width: 100%;
  --max-width: 100%;
  --height: 100%;

  .modal-wrapper.ion-overlay-wrapper.sc-ion-modal-ios {
    background-color: transparent;
  }
`;

export const StyledActionSheet = styled.div`
  z-index: 20002;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: block;
  position: fixed;
  background-color: rgba(0,0, 0, 0.5);
`;

export const StyledActionSheetContainer = styled.div`
  padding: 16px;
  position: relative;
  height: 100%;
  justify-content: start;
  
  &.t-p-50 {
    padding-top: 50px !important;
  }
  
  // width: 100%;
  // display: flex;
  // flex-direction: column;
  // justify-content: center;
  // align-items: center;
  // padding: 37px 40px 49px;
  // overflow: hidden;

  img.close {
    position: absolute;
    top: 16px;
    right: 16px;
  }

  &.small-padding {
    padding: 16px 14px 24px;
  }

  max-height: calc(100% - 40px);

  // position: absolute;
  // bottom: 0;
  // background: #FFFFFF;
  // border-top-left-radius: 20px;
  // border-top-right-radius: 20px;
  //
  // animation-name: show;
  // animation-duration: 500ms;

  &.hide {
    animation-name: hide;
    animation-duration: 750ms;
  }

  .image {
    margin-bottom: 20px;
  }

  @keyframes show {
    from {
      bottom: -200%;
    }
    to {
      bottom: 0;
    }
  }

  @keyframes hide {
    from {
      bottom: 0;
    }
    to {
      bottom: -200%;
    }
  }

  div.avatar {
  	width: 80px;
  	height: 80px;
  	background: #0085FF;
	box-shadow: 0px 24px 48px rgba(0, 133, 255, 0.25);
	border-radius: 40px;
	position: relative;
	margin-left: auto;
	margin-right: auto;
	display: flex;
	align-items: center;
	justify-content: center;

	img {
		border-radius: 100px;
	}
  }

  div.avatar::before {
  	content: ' ';
    display: block;
    top: -14px;
    left: -14px;
    position: absolute;
	background: linear-gradient(180deg, rgba(0, 133, 255, 0.25) 0%, rgba(255, 255, 255, 0) 100%);
	width: 108px;
    height: 108px;
    border-radius: 55px;
  }
`;
