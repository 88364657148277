import React, {useState} from 'react';
import {
    StyledContractAppendixButton,
    StyledContractButton,
    StyledContractContainer,
    StyledContractProps
} from './contract.style';
import ClockImage from '../../assets/images/time-clock.svg';
import ExactLogoImage from '../../assets/images/exact-logo.svg';
import AasLogoImage from '../../assets/images/aas-logo.svg';
import WarningImage from '../../assets/images/warning-red.svg';
import CheckMarkImage from '../../assets/images/checkMarkGreen.svg';
import AttachementImage from '../../assets/images/attachement-white.svg';
import {Contract as ContractModel, ContractAdditionalDocument, ContractAppendix} from '../../models/contract';
import {useTranslation} from "react-i18next";
import moment from "moment";
import {ContractStatus} from "../../enums/contractStatus";
import {StyledButton, StyledButtonBadge, StyledWiteButton} from '../button/button.style';
import {ContractCommentsStatus} from "../../enums/contractCommentsStatus";
import {Links} from "../../app/links";
import {useHistory} from 'react-router-dom';
import ContractAgreementPreviewModal from "../../app/contractPage/modals/contractAgreementPreviewModal.component";

const Contract: React.FC<StyledContractProps> = (props: StyledContractProps) => {
    const {t} = useTranslation();
    let contract: ContractModel = props.contract;
    let children: ContractModel[]|null|undefined = props.childrenContracts;
    let appendixes: ContractAppendix[]|null|undefined = props.appendixes;
    let additionalDocuments: ContractAdditionalDocument[]|null|undefined = props.additionalDocuments;
    const history = useHistory();

    function handleClick() {
        if (props.onClick) {
            props.onClick()
        }
    }

    const [showContractAgreementPreviewModal, updateShowContractAgreementPreviewModal] = useState<boolean>(false);

    return (
        <StyledContractContainer onClick={handleClick}>
            <small><img
                src={ClockImage}/><span>{moment(contract.start_at).format('DD.MM.YYYY')}</span> - <span>{contract.terminated_at ? moment(contract.terminated_at).format('DD.MM.YYYY') : moment(contract.end_at).format('DD.MM.YYYY')}</span></small>
            <h1>{t('contractPage.contractNumber')}: {contract.number}</h1>
            <h2><img src={contract.company_code === 'AASPL' ? AasLogoImage : ExactLogoImage}/>{contract.company_name}
            </h2>
            {appendixes && appendixes.reverse().map((appendix: ContractAppendix, key) => {
                return <StyledContractAppendixButton
                    key={key}
                    appendix={appendix}
                    contract={contract}
                    status={appendix.sign_status}
                    commentsStatus={appendix.comments_status}
                    onClick={(e?: React.MouseEvent) => {
                        history.push(Links.contractAppendix + '/' + appendix.id)
                    }}>
                    {
                        (appendix.sign_status !== ContractStatus.SIGNED && !appendix.is_active) &&
                        <img src={WarningImage}/>
                    }
                    {
                        (appendix.comments_status) && appendix.is_active &&
                        <img src={WarningImage}/>
                    }
                    {
                        (appendix.sign_status === ContractStatus.SIGNED) && appendix.is_active &&
                        <img src={CheckMarkImage}/>
                    }
                    {
                        !appendix.comments_status && (appendix.sign_status === ContractStatus.SIGNED_BY_KO || appendix.sign_status === ContractStatus.WAITING_FOR_WORKER_SIGN) && appendix.is_active &&
                        <span>{t("contractPage.list.appendix.signed_by_ko")}</span>
                    }
                    {
                        appendix.comments_status === ContractCommentsStatus.COMMENTED_BY_WORKER && appendix.is_active &&
                        <span>{t("contractPage.list.appendix.commented")}</span>
                    }
                    {
                        appendix.comments_status === ContractCommentsStatus.COMMENTS_REJECTED_BY_KO && appendix.is_active &&
                        <span>{t("contractPage.list.appendix.commentsRejected")}</span>
                    }
                    {
                        appendix.comments_status === ContractCommentsStatus.COMMENTS_ACCEPTED_BY_KO && appendix.is_active &&
                        <span>{t("contractPage.list.appendix.commentsAccepted")}</span>
                    }
                    {
                        appendix.comments_status === ContractCommentsStatus.COMMENTED_BY_WORKER && appendix.is_active &&
                        <StyledButtonBadge>{appendix.sign_comments.length}</StyledButtonBadge>
                    }
                    {
                        (appendix.sign_status === ContractStatus.WAITING_FOR_KO_SIGN && appendix.is_active) &&
                        <span>{t("contractPage.list.appendix.waiting_for_ko_sign")}</span>
                    }
                    {
                        (appendix.sign_status === ContractStatus.SIGNED && appendix.is_active) &&
                        <span>{t("contractPage.list.appendix.signed")}</span>
                    }
                    {
                        (appendix.sign_status === ContractStatus.SIGNED && !appendix.is_active) &&
                        <span>{t("contractPage.list.appendix.completed")}</span>
                    }
                    {
                        (appendix.sign_status !== ContractStatus.SIGNED && appendix.sign_status !== ContractStatus.REJECTED_BY_WORKER && !appendix.is_active) &&
                        <span>{t("contractPage.list.appendix.neverSignedCompleted")}</span>
                    }
                    {
                        appendix.sign_status === ContractStatus.REJECTED_BY_WORKER &&
                        <span>{t("contractPage.list.appendix.rejected")}</span>
                    }
                    {
                        appendix.sign_status === ContractStatus.ENDED &&
                        <span>{t("contractPage.list.appendix.ended")}</span>
                    }
                </StyledContractAppendixButton>
            })}
            {children && children.reverse().map((child: ContractModel, key) => {
                return <StyledContractButton
                    key={key}
                    contract={child}
                    status={ContractStatus.SIGNED}
                    commentsStatus={null}
                    onClick={(e?: React.MouseEvent) => {
                        history.push(Links.contract + '/' + child.id)
                    }}>
                    <img src={CheckMarkImage}/>
                    <span>{t("contractPage.list.administrationTypeChange")}</span>
                </StyledContractButton>
            })}
            <StyledContractButton
                contract={props.contract}
                status={props.status}
                commentsStatus={props.commentsStatus}
                onClick={(e?: React.MouseEvent) => {
                    history.push(Links.contract + '/' + contract.id)
                }}>
                {
                    (props.status !== ContractStatus.SIGNED && !props.contract.is_active) &&
                    <img src={WarningImage}/>
                }
                {
                    (props.commentsStatus) && contract.is_active &&
                    <img src={WarningImage}/>
                }
                {
                    (props.status === ContractStatus.WAITING_FOR_DOCUMENTS || props.status === ContractStatus.DOCUMENTS_STATEMENT_VERIFICATION || props.status === ContractStatus.SIGNED_WITH_DOCUMENTS_STATEMENT || props.status === ContractStatus.WAITING_FOR_ADDITIONAL_DOCUMENTS) && contract.is_active &&
                    <img src={AttachementImage} />
                }
                {
                    (props.status === ContractStatus.SIGNED || props.status === ContractStatus.SIGNED_WITH_ADDITIONAL_DOCUMENTS) && contract.is_active &&
                    <img src={CheckMarkImage} />
                }
                {
                    !props.commentsStatus && props.status === ContractStatus.SIGNED_BY_WORKER && contract.is_active &&
                    <span>{t("contractPage.list.signedByWorker")}</span>
                }
                {
                    !props.commentsStatus && props.status === ContractStatus.WAITING_FOR_KO_SIGN && contract.is_active &&
                    <span>{t("contractPage.list.waitingForKoSign")}</span>
                }
                {
                    !props.commentsStatus && (props.status === ContractStatus.WAITING_FOR_WORKER_SIGN || props.status === ContractStatus.WAITING_FOR_WORKER_SIGN_STATEMENT) && contract.is_active &&
                    <span>{t("contractPage.list.waitingForWorkerSign")}</span>
                }
                {
                    !props.commentsStatus && props.status === ContractStatus.SEND_BY_KO && contract.is_active &&
                    <span>{t("contractPage.list.sendByKo")}</span>
                }
                {
                    props.commentsStatus === ContractCommentsStatus.COMMENTED_BY_WORKER && contract.is_active &&
                    <span>{t("contractPage.list.commented")}</span>
                }
                {
                    props.commentsStatus === ContractCommentsStatus.COMMENTS_REJECTED_BY_KO && contract.is_active &&
                    <span>{t("contractPage.list.commentsRejected")}</span>
                }
                {
                    props.commentsStatus === ContractCommentsStatus.COMMENTS_ACCEPTED_BY_KO && contract.is_active &&
                    <span>{t("contractPage.list.commentsAccepted")}</span>
                }
                {
                    props.commentsStatus === ContractCommentsStatus.COMMENTED_BY_WORKER && contract.is_active &&
                    <StyledButtonBadge>{contract.sign_comments.length}</StyledButtonBadge>
                }
                {
                    (props.status === ContractStatus.SIGNED && contract.is_active) &&
                    <span>{t("contractPage.list.signed")}</span>
                }
                {
                    (props.status === ContractStatus.SIGNED_WITH_ADDITIONAL_DOCUMENTS && contract.is_active) &&
                    <span>{t("contractPage.list.signedWithAdditionalDocuments")}</span>
                }
                {
                    ((props.status === ContractStatus.SIGNED || props.status === ContractStatus.SIGNED_WITH_ADDITIONAL_DOCUMENTS) && !contract.is_active) &&
                    <span>{t("contractPage.list.completed")}</span>
                }
                {
                    (props.status !== ContractStatus.SIGNED && props.status !== ContractStatus.SIGNED_WITH_ADDITIONAL_DOCUMENTS && props.status !== ContractStatus.TERMINATED && props.status !== ContractStatus.REJECTED_BY_WORKER && !contract.is_active) &&
                    <span>{t("contractPage.list.neverSignedCompleted")}</span>
                }
                {
                    props.status === ContractStatus.TERMINATED &&
                    <span>{t("contractPage.list.terminated")}</span>
                }
                {
                    props.status === ContractStatus.REJECTED_BY_WORKER &&
                    <span>{t("contractPage.list.rejected")}</span>
                }
                {
                    !props.commentsStatus && (props.status === ContractStatus.WAITING_FOR_DOCUMENTS || props.status === ContractStatus.DOCUMENTS_STATEMENT_VERIFICATION || props.status === ContractStatus.SIGNED_WITH_DOCUMENTS_STATEMENT) && contract.is_active &&
                    <span>{t("contractPage.list.waitingForDocuments")}</span>
                }
                {
                    !props.commentsStatus && props.status === ContractStatus.DOCUMENTS_IN_VERIFICATION && contract.is_active &&
                    <span>{t("contractPage.list.documentsInVerification")}</span>
                }
                {
                    !props.commentsStatus && props.status === ContractStatus.WAITING_FOR_ADDITIONAL_DOCUMENTS && contract.is_active &&
                    <span>{t("contractPage.list.waitingForAdditionalDocuments")}</span>
                }
            </StyledContractButton>
            {
                additionalDocuments && additionalDocuments.length > 0 &&
                <StyledWiteButton onClick={props.onAdditionalFilesClick}>{t("contractPage.list.termsOfContract")}</StyledWiteButton>
            }
            {contract && props.status === ContractStatus.SIGNED && contract.is_agreement &&
                <StyledButton onClick={() => updateShowContractAgreementPreviewModal(true)}>
                    {t('contractPage.content.downloadAgreementPdf')}
                </StyledButton>
            }
            {contract && contract.is_agreement &&
                <ContractAgreementPreviewModal
                isOpen={showContractAgreementPreviewModal}
                onClose={() => updateShowContractAgreementPreviewModal(false)}
                contract={contract}
                > </ContractAgreementPreviewModal>
            }

        </StyledContractContainer>
    );
};

export default Contract;
