import React, {useEffect, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import Modal from '../../../components/modal/modal.component';
import {
    IonButtons, IonHeader,
} from "@ionic/react";
import ExactPeopleImg from "../../../assets/images/logo.svg";
import {StyledButton, StyledHeaderButton, StyledHeaderButtonImage} from "../../../components/button/button.style";
import {StyledContent} from "./recruitmentPathTestModal.style";
import 'survey-core/defaultV2.min.css';
import {Model} from 'survey-core';
import {StyledIonRecruitmentToolbar} from "../panes/recruitmentPathPane.style";
import BackButtonImage from "../../../assets/images/recruitment/back.svg";
import {StyledIonTitle} from "../../../components/content/content.style";
import {StyledFooter} from "../../../components/pane/pane.style";
import {disableSwipeToClose} from "../../../utils/tools/modals";

type AdaptiveTestInfoModalModalProps = {
    isOpen: boolean;
    onClose: () => void;
    openAdaptiveTest: () => void;
};

const AdaptiveTestInfoModal: React.FC<AdaptiveTestInfoModalModalProps> = (props: AdaptiveTestInfoModalModalProps) => {

    const {t} = useTranslation();

    const [showModal, updateShowModal] = useState<boolean>(false);

    const handleClose = () => {
        props.onClose();
    };

    useEffect(() => {
        handleOpen();
    });

    const handleOpen = () => {
        updateShowModal(props.isOpen);
    };

    return (
        <Modal
            isOpen={showModal}
            canDismiss={disableSwipeToClose}
            maxHeight={'80%'}
            onDidDismiss={() => handleClose()}>
            <IonHeader className="ion-no-border">
                <StyledIonRecruitmentToolbar className="white">
                    <IonButtons slot="start">
                        <StyledHeaderButton onClick={() => handleClose()}>
                            <StyledHeaderButtonImage src={BackButtonImage}></StyledHeaderButtonImage>
                        </StyledHeaderButton>
                    </IonButtons>
                    <StyledIonTitle>
                        <div className="logo">
                            <img src={ExactPeopleImg} />
                        </div>
                    </StyledIonTitle>>
                </StyledIonRecruitmentToolbar>
            </IonHeader>
            <StyledContent>
                <h1 className="title full justify">{t("recruitment.recruitmentPath.test3.info.title")}</h1>
                <div className="content full-height justify">
                    {t("recruitment.recruitmentPath.test3.info.content")}
                </div>

                <StyledFooter className="modal no-shadow">
                    <StyledButton
                        onClick={() => props.openAdaptiveTest()}>{t("recruitment.recruitmentPath.test3.info.continue")}</StyledButton>
                </StyledFooter>
            </StyledContent>
        </Modal>
    );
};

export default AdaptiveTestInfoModal;