import React, {useEffect, useState} from 'react';
import { useTranslation } from 'react-i18next';
import { StyledRoleActionSheetContainer } from './roleActionSheet.style';
import { StyledDataTable } from '../../../../../../components/content/content.style';
import VectorImage from '../../../../../../assets/images/checkMarkGreen.svg';
import { useSwipeable } from 'react-swipeable';
import { Profile } from "../../../../../../models/profile";
import { StyledIonModal } from "../../../../../../components/modal/modal.style";

type RoleActionSheetProps = {
    isVisible: boolean;
    selectedProfile?: Profile,
    onDismiss: () => void;
    onSelect: (profile: Profile) => void;
    roles?: Profile[];
}

const RoleActionSheet: React.FC<RoleActionSheetProps> = (props: RoleActionSheetProps) => {

    const [isVisible, changeVisibility] = useState(false);
    const {t} = useTranslation();

    useEffect(() => {
        changeVisibility(props.isVisible);
    });

    const config = {
        delta: 10,                            // min distance(px) before a swipe starts
        preventDefaultTouchmoveEvent: false,  // call e.preventDefault *See Details*
        trackTouch: true,                     // track touch input
        trackMouse: false,                    // track mouse input
        rotationAngle: 0,                     // set a rotation angle
      };

    const handlers = useSwipeable({
        onSwipedDown: (eventData) => {
            props.onDismiss();
        },
        ...config,
    });

    return isVisible ? (
        <StyledIonModal
            isOpen={props.isVisible}
            onDidDismiss={() => props.onDismiss()}
            initialBreakpoint={0.4}
            breakpoints={[0, 0.4, 0.99]}
        >
                <StyledRoleActionSheetContainer className="t-p-50" onClick={(e) => e.stopPropagation()}>
                    <StyledDataTable className="no-margin">
                        { props.roles?.length && props.roles.map((data, i) => <div key={i} className="data-row" onClick={() => {
                            props.onSelect(data);
                            props.onDismiss();
                        }}>
                                                            <div className="data-icon-title bolded min-height">{t("profileTab.roles."+data.type.toLowerCase())}</div>
                                                            <div className="data-action">
                                                                {data.id === props.selectedProfile?.id && <img src={VectorImage} />}
                                                            </div>
                                                            <div className="data-details small extended">
                                                                {data.company?.name}
                                                            </div>
                                                        </div>)
                        }
                   </StyledDataTable>
                </StyledRoleActionSheetContainer>
        </StyledIonModal>
    ) : (<span></span>);
};

export default RoleActionSheet;
