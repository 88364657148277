import {IonSelect} from '@ionic/react';
import styled from 'styled-components';

export const StyledIonSelect = styled(IonSelect)`
    background: #F4F4F9;
    border-radius: 12px;
    padding: 0 10px;
  
    font-size: 12px;
    line-height: 22px;
    letter-spacing: -0.408px;

    color: black;

    &.bolded {
        font-weight: 500;
        font-size: 12px;
        line-height: 15px;
        color: #000000;
    }

    &.focused {
        background: #FFFFFF;
        border: 1px solid #0085FF;
        box-sizing: border-box;
        border-radius: 12px;
        color: #000000;
    }
    
    &.city-select {
        opacity: 1!important;
    }
    
`;
