import styled from "styled-components";

export const StyledOffer = styled.div`
    ion-row {
        border-bottom: 1px solid #F6F6F8;
        padding-bottom: 15px;
    }

    .arrow {
        display: flex;
        align-items: flex-end;
        justify-content: flex-end;
    }

    .title {
        font-weight: 600;
        font-size: 14px;
        line-height: 17px;
        color: #000000;

        padding-top: 1px;
        padding-bottom: 12px;
    }

    .company {
        font-size: 12px;
        line-height: 140%;
        color: #888A98;
        display: flex;
        width: 100%;
        align-items: center;
        margin: 0;
        margin-top: 5px;
    
        img {
          max-height: 29px;
          margin-right: 9px;
        }
      }

    .details {
        font-weight: normal;
        font-size: 12px;
        line-height: 140%;
        color: #888A98;

        img {
            margin-right: 8px;
        }

        .value {
            font-weight: 600;
            font-size: 12px;
            line-height: 140%;
            color: #000000;
            margin: 2px 0;

            &.orange {
                color: #ffa620
            }

            &.red {
                color: #f43030;
            }

            &.green {
                color: #63c041;
            }
        }
    }
`;