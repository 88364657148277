import styled from 'styled-components';
import {ContractStatus} from '../../enums/contractStatus';
import {Contract, ContractAdditionalDocument, ContractAppendix} from '../../models/contract';
import {ContractCommentsStatus} from "../../enums/contractCommentsStatus";

export const StyledContractContainer = styled.div`
  width: 100%;
  border: 1px solid #EDEDF3;
  box-sizing: border-box;
  box-shadow: 0px 1px 1px rgba(136, 138, 152, 0.25);
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  margin-top: 12px;
  padding: 16px;

  small {
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 140%;
    color: #888A98;

    img {
      width: 10px;
      height: 10px;
      margin-right: 8px;
    }

    span {
      font-weight: bolder;
    }
  }

  h1 {
    font-family: Inter;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    color: #000000;
    margin: 0;
    margin-top: 8px;
  }

  h2 {
    font-family: Inter;
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 16px;
    color: #000000;
    display: flex;
    width: 100%;
    align-items: center;
    margin: 0;
    margin-top: 14px;
    margin-bottom: 16px;

    img {
      max-height: 29px;
      margin-right: 9px;
    }
  }
`;

export type StyledContractProps = {
    status: ContractStatus,
    commentsStatus: ContractCommentsStatus | null,
    contract: Contract,
    childrenContracts?: Contract[]|null,
    appendixes?: ContractAppendix[]|null,
    additionalDocuments?: ContractAdditionalDocument[]|null,
    onClick?: (e?: React.MouseEvent) => void
    onAdditionalFilesClick?: () => void;
};

export const StyledContractButton = styled.button<StyledContractProps>`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${props => contractFontColor(props.status, props.commentsStatus)};
  background: ${props => contractButtonColor(props.status, props.commentsStatus)};
  border-radius: 12px;
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  padding: 14px;
  position: relative;
  margin-top: 5px;

  img {
    width: 14px;
    height: 14px;
    margin-right: 8px;
  }
`;

export type StyledContractAppendixProps = {
    status: ContractStatus,
    commentsStatus: ContractCommentsStatus | null,
    appendix: ContractAppendix,
    contract: Contract,
    onClick?: (e?: React.MouseEvent) => void
};

export const StyledContractAppendixButton = styled.button<StyledContractAppendixProps>`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${props => contractFontColor(props.status, props.commentsStatus)};
  background: ${props => contractButtonColor(props.status, props.commentsStatus)};
  border-radius: 12px;
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  padding: 14px;
  position: relative;
  margin-top: 5px;

  img {
    width: 14px;
    height: 14px;
    margin-right: 8px;
  }
`;

function contractButtonColor(status: ContractStatus, commentsStatus: ContractCommentsStatus|null = null) {

    switch (commentsStatus) {
        case ContractCommentsStatus.COMMENTS_REJECTED_BY_KO:
            return 'rgba(244, 48, 48, 0.12)';
        case ContractCommentsStatus.COMMENTED_BY_WORKER:
        case ContractCommentsStatus.COMMENTS_ACCEPTED_BY_KO:
            return '#EDEDF3';
    }

    switch (status) {
        case ContractStatus.WAITING_FOR_WORKER_SIGN:
        case ContractStatus.WAITING_FOR_WORKER_SIGN_STATEMENT:
        case ContractStatus.WAITING_FOR_DOCUMENTS:
        case ContractStatus.DOCUMENTS_STATEMENT_VERIFICATION:
        case ContractStatus.SIGNED_WITH_DOCUMENTS_STATEMENT:
        case ContractStatus.SEND_BY_KO:
        case ContractStatus.SIGNED_BY_KO:
            return '#0085FF';
        case ContractStatus.REJECTED_BY_WORKER:
        case ContractStatus.TERMINATED:
            return 'rgba(244, 48, 48, 0.12)';
        case ContractStatus.SIGNED_BY_WORKER:
        case ContractStatus.SIGNED:
        case ContractStatus.WAITING_FOR_KO_SIGN:
        case ContractStatus.DOCUMENTS_IN_VERIFICATION:
            return '#EDEDF3';
    }
}

function contractFontColor(status: ContractStatus, commentsStatus: ContractCommentsStatus|null = null) {

    switch (commentsStatus) {
        case ContractCommentsStatus.COMMENTS_REJECTED_BY_KO:
            return '#F43030';
        case ContractCommentsStatus.COMMENTED_BY_WORKER:
        case ContractCommentsStatus.COMMENTS_ACCEPTED_BY_KO:
            return '#000000';
    }

    switch (status) {
        case ContractStatus.WAITING_FOR_WORKER_SIGN:
        case ContractStatus.WAITING_FOR_WORKER_SIGN_STATEMENT:
        case ContractStatus.SEND_BY_KO:
        case ContractStatus.WAITING_FOR_DOCUMENTS:
        case ContractStatus.DOCUMENTS_STATEMENT_VERIFICATION:
        case ContractStatus.SIGNED_WITH_DOCUMENTS_STATEMENT:
        case ContractStatus.SIGNED_BY_KO:
            return '#FFFFFF';
        case ContractStatus.REJECTED_BY_WORKER:
        case ContractStatus.TERMINATED:
            return '#F43030';
        case ContractStatus.SIGNED_BY_WORKER:
        case ContractStatus.SIGNED:
        case ContractStatus.WAITING_FOR_KO_SIGN:
        case ContractStatus.DOCUMENTS_IN_VERIFICATION:
            return '#000000';
    }
}
