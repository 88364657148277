import React, { useEffect, useState } from 'react';
import { StyledCircleImageContainer, StyledCircleImagePicture  } from './circleImage.style';
import PrivacyImage from  '../../assets/images/privacy.svg';
import LetterImage from '../../assets/images/letter.svg';
import TImage from '../../assets/images/t-white.svg';
import ConnectImage from '../../assets/images/connect.svg';
import CameraImage from '../../assets/images/camera-2.svg';
import NoteImage from '../../assets/images/empty-note.svg';
import AttachementImage from '../../assets/images/empty-attachement.svg';
import BriefCaseImage from '../../assets/images/briefcase-26.svg';
import ReportsImage from '../../assets/images/chart-pie-36.svg';
import AvatarImage from '../../assets/images/avatar-blue.svg';
import QrImage from '../../assets/images/barcode-qr.svg';
import PlanningImage from '../../assets/images/planning.svg';

type CircleImageProps = {
    image: ImageTypes,
    color?: string;
    className?: string;
    onClick?: (e: any) => any;
}

export enum ImageTypes {
    LETTER = 0,
    PRIVACY = 1,
    T = 2,
    CONNECT = 3,
    CAMERA = 4,
    ATTACHEMENT = 5,
    NOTE = 6,
    BRIEF_CASE = 7,
    REPORTS = 8,
    AVATAR = 9,
    PLANNING = 10,
    QR = 11,
}

const CircleImage: React.FC<CircleImageProps> = (props: CircleImageProps) => {
    const [image, updateImage] = useState<ImageTypes>(ImageTypes.PRIVACY);

    useEffect(() => {
        if (props.image !== image) {
            updateImage(props.image);
        }
    });

    return (
        <StyledCircleImageContainer className={props?.className} color={props.color} onClick={(e) => {
            if (props.onClick) {
                props.onClick(e);
            }
        }}>
            { image === ImageTypes.LETTER && <StyledCircleImagePicture src={LetterImage} /> }
            { image === ImageTypes.PRIVACY && <StyledCircleImagePicture src={PrivacyImage} /> }
            { image === ImageTypes.T && <StyledCircleImagePicture src={TImage} /> }
            { image === ImageTypes.CONNECT && <StyledCircleImagePicture src={ConnectImage} /> }
            { image === ImageTypes.CAMERA && <StyledCircleImagePicture src={CameraImage} /> }
            { image === ImageTypes.NOTE && <StyledCircleImagePicture src={NoteImage} /> }
            { image === ImageTypes.ATTACHEMENT && <StyledCircleImagePicture src={AttachementImage} /> }
            { image === ImageTypes.BRIEF_CASE && <StyledCircleImagePicture src={BriefCaseImage} /> }
            { image === ImageTypes.REPORTS && <StyledCircleImagePicture src={ReportsImage} /> }
            { image === ImageTypes.AVATAR && <StyledCircleImagePicture src={AvatarImage} /> }
            { image === ImageTypes.QR && <StyledCircleImagePicture src={QrImage} /> }
            { image === ImageTypes.PLANNING && <StyledCircleImagePicture src={PlanningImage} /> }
        </StyledCircleImageContainer>
    );
};

export default CircleImage;
