import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import Modal from '../../../components/modal/modal.component';
import {IonAlert, IonContent, useIonViewWillLeave} from '@ionic/react';
import {BarcodeScanner} from "@capacitor-community/barcode-scanner";

type QrScannerModalProps = {
    isOpen: boolean;
    onClose: () => void;
    onSubmit: (content: string) => void;
};

const QrScannerModal: React.FC<QrScannerModalProps> = (props: QrScannerModalProps) => {

    const {t} = useTranslation();
    const [showComments, updateShowComments] = useState<boolean>(false);
    const [showPermissionAlert, setShowPermissionAlert] = useState<boolean>(false);

    const handleClose = () => {
        props.onClose();
        stopScan();
    };

    useEffect(() => {
        handleOpen();
    }, [props.isOpen]);

    const handleOpen = () => {
        updateShowComments(props.isOpen);
        if (props.isOpen) {
            scanComment();
        }
    };

    const onSubmit = async (content: string) => {
        props.onSubmit(content);
    };

    useIonViewWillLeave(() => {
        stopScan();
    });

    const startScan = async () => {
        document.body.style.opacity="0";
        document.body.style.background = "transparent";
        BarcodeScanner.hideBackground(); // make background of WebView transparent

        const result = await BarcodeScanner.startScan(); // start scanning and wait for a result

        if (result.hasContent && result.content) {
            onSubmit(result.content);
            stopScan()
        }
    };

    const checkPermission = async () => {
        // check or request permission
        const status = await BarcodeScanner.checkPermission({ force: true });

        if (status.granted) {
            // the user granted permission
            return true;
        }

        return false;
    };

    const stopScan = () => {
        BarcodeScanner.showBackground();
        BarcodeScanner.stopScan();

        document.body.style.opacity="1";
        document.body.style.background = "";
    };

    const scanComment = async () => {
        let hasPermission = await checkPermission();
        if (!hasPermission) {
            setShowPermissionAlert(true);
        }
        startScan();
    }

    return (
        <Modal
            isOpen={showComments}
            disableClose={false}
            onDidDismiss={() => handleClose()}>

            <IonAlert
                isOpen={showPermissionAlert}
                onDidDismiss={() => setShowPermissionAlert(false)}
                header={t('common.permissions.alert')}
                buttons={[
                    {
                        text: t('common.alertCancel'),
                        role: 'cancel',
                        cssClass: 'secondary',
                        handler: () => {
                            setShowPermissionAlert(false);
                        },
                    },
                    {
                        text: t('common.permissions.goToAppSettings'),
                        handler: async () => {
                            setShowPermissionAlert(false);
                            BarcodeScanner.openAppSettings();
                        }
                    }
                ]}
            />
        </Modal>
    );
};

export default QrScannerModal;