import {appApi} from "./api.service";
import {Preferences} from "@capacitor/preferences";

let endpointPrefix = 'mobile-menu';

const getMenu = async () => {
    let profileId = await Preferences.get({'key': 'profile_id'});
    return appApi.get(endpointPrefix + '?profileId='+(profileId.value ? profileId.value : ''));
}

export {
	getMenu
};
