import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import Modal from '../../../components/modal/modal.component';
import {IonAlert, IonCol, IonGrid, IonItem, IonList, IonRow, IonSelectOption, useIonViewWillLeave} from '@ionic/react';
import {BarcodeScanner, SupportedFormat} from "@capacitor-community/barcode-scanner";
import {Toast} from "@capacitor/toast";
import moment from "moment";
import {ReportRowComponentLabel} from "./labelScannerModal.component";
import {StyledModalContent} from "../../../components/modal/modal.style";
import {StyledContent} from "./commentsModal.style";
import {StyledButton, StyledCircleButton} from "../../../components/button/button.style";
import {StyledIonSelect} from "../../../components/form/input/select.style";
import CloseImage from "../../../assets/images/e-remove.svg";
import TrashRedImage from "../../../assets/images/trash-can-red.svg";

type ManualScannerModalProps = {
    isOpen: boolean;
    isQr: boolean;
    onClose: () => void;
    onSubmit: (label: ReportRowComponentLabel) => void;
};

type ManualScannedDetailValue = {
    data: string
    field: string|null
}

const ManualLabelScannerModal: React.FC<ManualScannerModalProps> = (props: ManualScannerModalProps) => {

    const {t} = useTranslation();
    const [showModal, updateShowModal] = useState<boolean>(false);
    const [showPermissionAlert, setShowPermissionAlert] = useState<boolean>(false);
    const [label, updateLabel] = useState<ReportRowComponentLabel>({
        comment: null,
        quantity: null,
        deliveryNote: null,
        levNumber: null,
        serialNumber: null,
        componentNumberText: null,
        batchNumber: null,
    });
    const [scannedDetailsValues, updateScannedDetailsValues] = useState<ManualScannedDetailValue[]>();

    const handleClose = () => {
        props.onClose();
        stopScan();
    };

    useEffect(() => {
        handleOpen();
    }, [props.isOpen]);

    const handleOpen = () => {
        updateShowModal(props.isOpen);
        updateLabel({
            comment: null,
            quantity: null,
            deliveryNote: null,
            levNumber: null,
            serialNumber: null,
            componentNumberText: null,
            batchNumber: null,
        });

        if (props.isOpen) {
            scanLabel();
        }
    };

    const handleSubmit = async () => {
        if (scannedDetailsValues) {
            let tmpLabel = matchDetailDataWithLabel(scannedDetailsValues);
            if (tmpLabel) {
                props.onSubmit(tmpLabel);
            }
        }
    };

    useIonViewWillLeave(() => {
        stopScan();
    });

    const startScan = async () => {
        let scanStarted:any = undefined;
        let scanned:string[] = [];

        document.body.classList.add('scanner-active');
        BarcodeScanner.hideBackground(); // make background of WebView transparent

        BarcodeScanner.startScanning({
            targetedFormats: props.isQr ? [SupportedFormat.QR_CODE, SupportedFormat.DATA_MATRIX] : [
                SupportedFormat.ITF,
                SupportedFormat.ITF_14,
                SupportedFormat.CODE_39,
                SupportedFormat.CODE_39_MOD_43,
                SupportedFormat.CODE_93,
                SupportedFormat.CODE_128,
                SupportedFormat.CODABAR,
                SupportedFormat.UPC_A,
                SupportedFormat.UPC_E,
            ],
        }, async (value) => {
            let now = moment();
            if (scanStarted === undefined) {
                scanStarted = now;
            }

            if (value.hasContent && value.content) {
                if (props.isQr) {
                    let tmpScannedDetailsValues:ManualScannedDetailValue[] = [];
                    let qrData;
                    if (isValidGS1DataMatrix(value.content)) {
                        qrData = parseGS1DataMatrixCode(value.content);
                    } else if (isValidQr(value.content)) {
                        qrData = prepareAiQrData(value.content);
                    } else if (isValidVWDataMatrix(value.content)) {
                        qrData = parseVWQrData(value.content);
                    } else {
                        qrData = parseGenericQrData(value.content);
                    }

                    if (qrData) {
                        for (const key in qrData) {
                            if (qrData.hasOwnProperty(key)) {
                                const entry = qrData[key];
                                if (tmpScannedDetailsValues.filter(tmpDatum => {
                                    return entry.matchedField === tmpDatum.field;
                                }).length > 0) {
                                    tmpScannedDetailsValues.push({
                                        data: entry.value,
                                        field: null,
                                    })
                                } else {
                                    tmpScannedDetailsValues.push({
                                        data: entry.value,
                                        field: entry.matchedField,
                                    })
                                }
                            }
                        }
                    }

                    updateScannedDetailsValues(tmpScannedDetailsValues);
                    stopScan();
                } else {
                    if (!scanned.includes(value.content)) {
                        scanned.push(value.content);

                        await Toast.show({
                            text: value.content,
                            duration: 'short',
                            position: 'bottom',
                        });
                    }
                }
            }

            if (now.diff(scanStarted, 's') > 15) {
                let tmpScannedDetailsValues:ManualScannedDetailValue[] = [];
                for (const scannedValue of scanned) {
                    tmpScannedDetailsValues.push({
                        data: scannedValue,
                        field: null,
                    })
                }
                updateScannedDetailsValues(tmpScannedDetailsValues);
                stopScan();
            }
        });

        setTimeout(() => {
            BarcodeScanner.enableTorch();
        }, 1000);
    };

    const checkPermission = async () => {
        // check or request permission
        const status = await BarcodeScanner.checkPermission({ force: true });

        if (status.granted) {
            // the user granted permission
            return true;
        }

        return false;
    };

    const stopScan = () => {
        BarcodeScanner.showBackground();
        BarcodeScanner.stopScan();
        BarcodeScanner.disableTorch();

        document.body.classList.remove('scanner-active');
    };

    const scanLabel = async () => {
        await Toast.show({
            text: t('reportPage.reportForm.nestedReporting.scanningManualLabel'),
            duration: 'short',
            position: 'center',
        });

        await Toast.show({
            text: t('reportPage.reportForm.nestedReporting.swipeDownToClose'),
            duration: 'short',
            position: 'center',
        });

        let hasPermission = await checkPermission();
        if (!hasPermission) {
            setShowPermissionAlert(true);
        }
        startScan();
    }

    const labelNames = [
        'deliveryNote',
        'componentNumberText',
        'levNumber',
        'serialNumber',
        'quantity',
        'batchNumber',
    ];

    const possibleLabelNames = (selected: ManualScannedDetailValue) => {
        return labelNames.filter(value => {
            let stateVal = scannedDetailsValues?.filter(v => {
                return v.field == value
            })[0];

            return stateVal === undefined || stateVal == selected;
        });
    }

    const matchWithField = (scannedDetailsValue: ManualScannedDetailValue, index: number, value: string) => {
        const tmp = [ ...(scannedDetailsValues || []) ];
        const valueIndex = scannedDetailsValues?.indexOf(scannedDetailsValue)
        if (valueIndex !== undefined && valueIndex !== -1) {
            tmp[valueIndex].field = value;
        }
        updateScannedDetailsValues(tmp)
    }

    const matchDetailDataWithLabel = (data: ManualScannedDetailValue[]) => {
        let tmpLabel = label;
        for (const item of data) {
            switch (item.field) {
                case 'deliveryNote':
                    tmpLabel.deliveryNote = item.data;
                    break;
                case 'componentNumberText':
                    tmpLabel.componentNumberText = item.data;
                    break;
                case 'levNumber':
                    tmpLabel.levNumber = item.data;
                    break;
                case 'serialNumber':
                    tmpLabel.serialNumber = item.data;
                    break;
                case 'quantity':
                    tmpLabel.quantity = item.data.replace(/[^0-9]/g, '');
                    break;
                case 'batchNumber':
                    tmpLabel.batchNumber = item.data;
                    break;
            }
        }

        return tmpLabel;
    }

    const matchFieldWithAiCode = (code: string) => {
        switch (code) {
            case '00':
            case '21':
                return 'serialNumber';
            case '10':
                return 'batchNumber';
            case '30':
                return 'quantity';
            case '91':
            case '92':
                return 'deliveryNote';
            case '241':
                return 'componentNumberText';
            case '251':
                return 'levNumber';
            default:
                return null;
        }
    }

    const matchFieldWithAiGS1DataMatrixCode = (code: string) => {
        const ai = code.substring(0, 2); // Get the first two characters as AI

        if (ai === '1P') {
            return 'componentNumberText';
        } else if (code.startsWith('Q')) {
            return 'quantity';
        } else if (ai === '1T') {
            return 'batchNumber';
        } else if (code.startsWith('16D')) {
            return 'deliveryNote';
        }

        return null;
    }

    const prepareAiQrData = (data: string) => {
        const pattern = /\((\d{2,3})\)([^()]+)/g;
        const matchesArray = Array.from(data.matchAll(pattern));
        const parsedData: { [key: string]: { description: string, value: string, matchedField: string|null } } = {};

        const aiDescriptions: { [key: string]: string } = {
            '00': 'Serial Shipping Container Code (SSCC)',
            '01': 'Global Trade Item Number (GTIN)',
            '10': 'Batch or Lot Number',
            '11': 'Production Date (YYMMDD)',
            '13': 'Packaging Date (YYMMDD)',
            '15': 'Best Before Date (YYMMDD)',
            '17': 'Expiration Date (YYMMDD)',
            '20': 'Internal Product Variant',
            '21': 'Serial Number',
            '22': 'Secondary Data Fields',
            '30': 'Count of Items',
            '37': 'Count of Trade Items',
            '240': 'Additional Product Identification',
            '241': 'Customer Part Number',
            '242': 'Made-To-Order Variation Number',
            '250': 'Secondary Serial Number',
            '251': 'Reference to Source Entity',
            '253': 'Global Document Type Identifier (GDTI)',
            '254': 'GLN Extension Component',
            '400': 'Customer Purchase Order Number',
            '401': 'GINC (Global Identification Number for Consignment)',
            '402': 'GSIN (Global Shipment Identification Number)',
            '403': 'Routing Code',
            '410': 'Ship To - Deliver To GLN',
            '411': 'Bill To - Invoice To GLN',
            '412': 'Purchased From GLN',
            '413': 'Ship For - Deliver For - GLN',
            '414': 'Identification of a Physical Location (GLN)',
            '415': 'GLN of the Invoicing Party',
            '416': 'GLN of the Production or Service Location',
            '420': 'Ship To Postal Code within a Single Postal Authority',
            '421': 'Ship To Postal Code with Three-Digit ISO Country Code',
            '422': 'Country of Origin of a Trade Item',
            '423': 'Country of Initial Processing',
            '424': 'Country of Processing',
            '425': 'Country of Disassembly',
            '426': 'Country of Full Disassembly',
            '427': 'Country of Partial Disassembly',
        };

        for (const match of matchesArray) {
            if (match[1] && match[2]) {
                const ai = match[1];
                const value = match[2];
                const description = aiDescriptions[ai] || 'Unknown AI';
                const matchedField = matchFieldWithAiCode(ai);
                parsedData[ai] = { description, value, matchedField };
            }
        }

        return parsedData;
    }

    function isValidGS1DataMatrix(input: string): boolean {
        return input.startsWith('[)>') && input.endsWith('\x04')
    }

    function isValidVWDataMatrix(input: string): boolean {
        return input.includes('MHD');
    }

    function isValidQr(input: string): boolean {
        const pattern = /\((\d{2,3})\)([^()]+)/g;
        const matchesArray = Array.from(input.matchAll(pattern));
        return matchesArray.length > 0;
    }

    function parseGS1DataMatrixCode(input: string){
        const parsedData: { [key: string]: { description: string, value: string, matchedField: string|null } } = {};

        if (!isValidGS1DataMatrix(input)) {
            return null;
        }

        // Remove leading '[)>' and trailing EOT '\x04'
        const trimmedInput = input.slice(3, -1);

        // Split the string by the delimiter '\x1E'
        const parts = trimmedInput.split('\x1E');

        // Remove empty elements that might result from splitting
        const cleanedParts = parts.filter(part => part.trim() !== '');

        // Further split each part by the delimiter '\x1D'
        const parsedParts = cleanedParts.flatMap(part => part.split('\x1D'));

        parsedParts.forEach(part => {
            const matchedField = matchFieldWithAiGS1DataMatrixCode(part);
            const ai = part.substring(0, 2); // Get the first two characters as AI
            let value;

            if (ai === '1P') {
                value = part.substring(2)
            } else if (part.startsWith('Q')) {
                value = part.substring(1);
            } else if (ai === '1T') {
                value = part.substring(2)
            } else if (part.startsWith('16D')) {
                value = part.substring(3)
            }
            if (value) {
                const description = ai;
                parsedData[ai] = { description, value, matchedField };
            }
        });

        return parsedData;
    }

    const parseVWQrData = (data: string) => {
        return parseGenericQrData(data);
    }

    const parseGenericQrData = (data: string) => {
        const parsedData: { [key: string]: { description: string, value: string, matchedField: string|null } } = {};
        const replacedString = data.replace(/[^\w\s]/g, ';'); // [^\w\s] dopasowuje wszystkie znaki oprócz liter, cyfr i białych znaków
        const matchesArray = replacedString.split(';').filter(Boolean);

        matchesArray.forEach((value, key) => {
            let matchedField = null;
            let description = '';
            parsedData[key] = { description, value, matchedField };
        })

        return parsedData;
    }

    const removeData = (scannedDetailsValue: ManualScannedDetailValue) => {
        const tmp = [ ...(scannedDetailsValues || []) ];
        const valueIndex = scannedDetailsValues?.indexOf(scannedDetailsValue)
        if (valueIndex !== undefined && valueIndex !== -1) {
            tmp.splice(valueIndex, 1);
        }
        updateScannedDetailsValues(tmp)
    }

    return (
        <Modal
            isOpen={showModal}
            disableClose={true}
            onDidDismiss={() => handleClose()}>

            <StyledModalContent className="modal-wrap">
                <img src={CloseImage} className="close" onClick={() => handleClose()}/>
                <h1 className="title">{t('reportPage.manualLabel.title')}</h1>
                <StyledContent className="content">
                    {scannedDetailsValues && scannedDetailsValues.length > 0 && scannedDetailsValues.map((value, i) =>
                        <IonRow key={i}>
                            <IonCol size="5">
                                <p className="listLabel">{value.data}</p>
                            </IonCol>
                            <IonCol size="6">
                                <StyledIonSelect className="bolded"
                                                 okText={t('common.selectOk')}
                                                 cancelText={t('common.selectCancel')}
                                                 justify="end"
                                                 value={value.field}
                                                 interfaceOptions={
                                                     {
                                                         header: t('common.select')
                                                     }
                                                 }
                                                 onIonChange={e => {
                                                     matchWithField(value, i, e.detail.value)
                                                 }}>
                                    <IonSelectOption value="">
                                        {t('common.selectEmpty')}
                                    </IonSelectOption>
                                    {possibleLabelNames(value).map((possibleValue: string, key) =>
                                        <IonSelectOption key={key}
                                                         value={possibleValue}>{t('reportPage.reportForm.nestedReporting.' + possibleValue)}</IonSelectOption>)}
                                </StyledIonSelect>
                            </IonCol>
                            <IonCol size="1">
                                <StyledCircleButton className="white" onClick={() => removeData(value)}><img src={TrashRedImage}/></StyledCircleButton>
                            </IonCol>
                        </IonRow>
                    )}

                    <StyledButton disabled={scannedDetailsValues?.length === 0}
                                  onClick={handleSubmit}>{t('reportPage.manualLabel.save')}</StyledButton>
                </StyledContent>
            </StyledModalContent>

            <IonAlert
                isOpen={showPermissionAlert}
                onDidDismiss={() => setShowPermissionAlert(false)}
                header={t('common.permissions.alert')}
                buttons={[
                    {
                        text: t('common.alertCancel'),
                        role: 'cancel',
                        cssClass: 'secondary',
                        handler: () => {
                            setShowPermissionAlert(false);
                        },
                    },
                    {
                        text: t('common.permissions.goToAppSettings'),
                        handler: async () => {
                            setShowPermissionAlert(false);
                            BarcodeScanner.openAppSettings();
                        }
                    }
                ]}
            />
        </Modal>
    );
};

export default ManualLabelScannerModal;