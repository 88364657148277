import React, {useEffect, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import Modal from '../../../components/modal/modal.component';
import {StyledModalContent} from '../../../components/modal/modal.style';
import {
    AccordionGroupCustomEvent,
    IonAccordion,
    IonAccordionGroup, IonButtons, IonCol, IonGrid, IonHeader,
    IonItem,
    IonLabel, IonList, IonRow,
    useIonViewWillEnter
} from "@ionic/react";
import RecruitmentImage from '../../../assets/images/recruitment.svg';
import ExactPeopleImg from "../../../assets/images/logo.svg";
import {
    StyledButton,
    StyledHeaderButton,
    StyledHeaderButtonImage,
    StyledWiteButton
} from "../../../components/button/button.style";
import {StyledContent} from "./recruitmentPathTestModal.style";
import Test1 from "../components/test1/test1.component";
import Test2 from "../components/test2/test2.component";
import Test3 from "../components/test3/test3.component";
import {RecruitmentPathTestType} from "../../../enums/recruitmentPathTestType";
import {RecruitmentPathData} from "../../../models/recruitmentPathData";
import {StyledFooter} from "../../../components/pane/pane.style";
import {abortPath, getTestPathByHash} from "../../../services/recruitment.service";
import Pane from "../../../components/pane/pane.component";
import RecruitmentPathSkeleton from "../../../components/recruitment/recruitmentPathSkeleton.component";
import BackButtonImage from "../../../assets/images/recruitment/back.svg";
import {StyledIonTitle, StyledIonToolbar} from "../../../components/content/content.style";
import {StyledIonRecruitmentToolbar} from "../panes/recruitmentPathPane.style";
import {tr} from "date-fns/locale";
import {inProgress} from "../../../services/api.service";
import {Capacitor} from "@capacitor/core";
import {App as AppPlugin} from "@capacitor/app";
import {disableSwipeToClose} from "../../../utils/tools/modals";

type RecruitmentPathFaqModalProps = {
    isOpen: boolean;
    onClose: () => void;
    tests: [];
    hash?: string;
    results: [];
    durations: [];
    version?: number;
    series: any;
};

const RecruitmentPathTestModal: React.FC<RecruitmentPathFaqModalProps> = (props: RecruitmentPathFaqModalProps) => {

    const {t} = useTranslation();

    const [showRecruitmentPathFaq, updateRecruitmentPathFaq] = useState<boolean>(false);
    const [currentPath, updateCurrentPath] = useState<string>(RecruitmentPathTestType.TEST_1);
    const [currentTest, updateCurrentTest] = useState<number>(0);
    const [availablePaths, updateAvailablePaths] = useState([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [title, updateTitle] = useState<string>('');
    const [showExitConfirm, updateShowExitConfirm] = useState<boolean>(false);
    const [canDismiss, updateCanDismiss] = useState<boolean>(false);
    const [isTestInProgress, updateIsTestInProgress] = useState<string|null>();

    const header = useRef<HTMLIonHeaderElement>(null);

    if (Capacitor.isNative) {
        AppPlugin.addListener("backButton", (e) => {
            updateIsTestInProgress(null);
        })
    }

    const handleClose = (manual: boolean) => {
        updateCanDismiss(true);

        if (isTestInProgress && manual == true) {
            updateShowExitConfirm(true);
        } else {
            close();
        }
    };

    useEffect(() => {
        handleOpen();
    });

    useEffect(() => {
        updateShowExitConfirm(false);
        
        if (props.isOpen == true && Object.keys(props.tests).length > 0) {
            if (testPassed(props.results, RecruitmentPathTestType.TEST_1)) {
                if (!testPassed(props.results, RecruitmentPathTestType.TEST_2)) {
                    goToPath(RecruitmentPathTestType.TEST_2);
                }
            } else {
                updateCurrentPath(RecruitmentPathTestType.TEST_1);
            }
        } else {
            updateCurrentPath('');
        }
        updateTitle('');
    }, [props.isOpen]);

    const handleOpen = () => {
        updateRecruitmentPathFaq(props.isOpen);
        if (props.isOpen == true) {
            updateCanDismiss(false);
        } else {
            updateCanDismiss(true);
        }
    };

    const goToPath = (type: string) => {
        if (Object.keys(props.tests).indexOf(type) != -1) {
            updateCurrentPath(type);
        }
    }

    const exitRecruitment = () => {
        updateCanDismiss(true);
        props.onClose();
        updateCurrentPath('');
    }

    const abortRecruitment = async () => {
        if (props.hash) {
            setLoading(true);
            await abortPath(props.hash)
                .then(response => {
                    exitRecruitment();
                })
                .catch(error => {
                    // todo what we show??
                });

            setLoading(false);
        }
    }

    const testPassed = (results: any, type: string) => {
        if (results
            && (type == RecruitmentPathTestType.TEST_1 || type == RecruitmentPathTestType.TEST_2)
            && results[type]) {
            return results[type].result;
        }

        return false;
    }

    const close = () => {
        updateCanDismiss(true);
        props.onClose();
    }

    const setTitle = (title: string) => {
        updateTitle(title);
    }

    const updateTestInProgress = (inProgress:string|null) => {
        updateIsTestInProgress(inProgress);
    }

    return (
        <Modal
            isOpen={showRecruitmentPathFaq}
            canDismiss={disableSwipeToClose}
            maxHeight={'80%'}
            onDidDismiss={() => {handleClose(false)}}>
            <StyledModalContent>
                <IonHeader ref={header} className="ion-no-border">
                    <StyledIonRecruitmentToolbar className="white">
                        <IonButtons slot="start">
                            <StyledHeaderButton onClick={() => handleClose(true)}>
                                <StyledHeaderButtonImage src={BackButtonImage}></StyledHeaderButtonImage>
                            </StyledHeaderButton>
                        </IonButtons>
                        <StyledIonTitle>
                            {title &&
                                <span className="title">{title}</span>}

                            {!title &&
                                <div className="logo">
                                    <img src={ExactPeopleImg} />
                                </div>
                            }
                        </StyledIonTitle>>
                    </StyledIonRecruitmentToolbar>
                </IonHeader>

                {loading &&
                    <Pane marginTop={35} paddingBottom={97}>
                        <IonList>
                            <RecruitmentPathSkeleton />
                        </IonList>
                    </Pane>
                }

                {!loading && !showExitConfirm && currentPath == RecruitmentPathTestType.TEST_1 && props.hash &&
                    <div className="content full-height">
                        <Pane marginTop={35} paddingBottom={157}>
                            <Test1 currentTest={currentTest} tests={props.tests} hash={props.hash}
                                goToNextPath={goToPath} exitRecruitment={exitRecruitment}
                                abortRecruitment={abortRecruitment} updateTitle={setTitle}
                                updateTestInProgress={(inProgress) => updateIsTestInProgress(inProgress)}
                                durations={props.durations} version={props.version} series={props.series}
                            ></Test1>
                        </Pane>
                    </div>
                }

                {!loading && !showExitConfirm && currentPath == RecruitmentPathTestType.TEST_2 && props.hash &&
                    <div className="content full-height">
                        <Pane marginTop={35} paddingBottom={57}>
                            <Test2 currentTest={currentTest} tests={props.tests} hash={props.hash}
                                goToNextPath={goToPath} exitRecruitment={exitRecruitment}
                                abortRecruitment={abortRecruitment} updateTitle={setTitle}
                                updateTestInProgress={(inProgress) => updateIsTestInProgress(inProgress)}
                                durations={props.durations} version={props.version} series={props.series}
                            ></Test2>
                        </Pane>
                    </div>
                }

                {!loading && !showExitConfirm && testPassed(props.results, RecruitmentPathTestType.TEST_1) && testPassed(props.results, RecruitmentPathTestType.TEST_2) &&
                     <div>
                        <h1 className="title full">{t("recruitment.recruitmentPath.allTestsPassed.title")}</h1>
                        <div className="content full-height" dangerouslySetInnerHTML={{__html: t("recruitment.recruitmentPath.allTestsPassed.content") || ''}}/>
                    </div>
                }

                {!loading && showExitConfirm &&
                    <div>
                        <h1 className="title full">{t("recruitment.recruitmentPath.confirm_exit.title")}</h1>
                        <div className="content full-height" dangerouslySetInnerHTML={{__html: t("recruitment.recruitmentPath.confirm_exit.content") || ''}}/>

                        <StyledFooter className="modal no-shadow">
                            <IonGrid>
                                <IonRow>
                                    <IonCol size="12">
                                        <StyledWiteButton
                                            onClick={() => close()}>{t("recruitment.recruitmentPath.confirm_exit.confirm")}</StyledWiteButton>
                                    </IonCol>
                                </IonRow>
                                <IonRow>
                                    <IonCol size="12">
                                        <StyledButton
                                            onClick={() => updateShowExitConfirm(false)}>{t("recruitment.recruitmentPath.confirm_exit.cancel")}</StyledButton>
                                    </IonCol>
                                </IonRow>
                            </IonGrid>
                        </StyledFooter>
                    </div>
                }
            </StyledModalContent>
        </Modal>
    );
};

export default RecruitmentPathTestModal;