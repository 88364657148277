import React, {MouseEvent, useContext, useEffect, useState} from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import useNavigation from '../../services/navigation.service';
import { StyledSubNavigationContainer, StyledSubNavigationButton } from './subNavigation.style';

type SubNavigationProps = {
    options: SubNavigationOption[];
    defaultValue?: number;
    marginTop?: number;
    light?: boolean;
    paramName?: string;
    onSelect: (option: SubNavigationOption) => void
}

export type SubNavigationOption = {
    name: string;
}

interface SubNavigationManager {
    setSelected: Function;
}

const contextSubNavigation: SubNavigationManager = {
    setSelected: () => {

    }
};

export const SubNavigationContext = React.createContext<SubNavigationManager>(contextSubNavigation);


const SubNavigation: React.FC<SubNavigationProps> = (props: SubNavigationProps) => {
    let [selected, setLastClicked] = useState(props.defaultValue || 0);
    const history = useHistory();
    const navigation = useNavigation(history);
    const location = useLocation<void>();

    const contextSubNavigation = useContext(SubNavigationContext);
    contextSubNavigation.setSelected = setLastClicked;

    useEffect(() => {
        if (!props.paramName) {
            return;
        }

		const urlOption = parseInt(navigation.getParam<string>(props.paramName));

		if (urlOption !== selected && !isNaN(urlOption)) {
			setLastClicked(urlOption);
            props.onSelect(props.options[urlOption]);
		}
	}, [location]);

    const onSelect = (option: SubNavigationOption, index: number, event: MouseEvent) => {
        const blockNavigationFunction = navigation.getBlockNavigation();

		if (typeof blockNavigationFunction === 'undefined' ||
			(typeof blockNavigationFunction === 'function' && !blockNavigationFunction())) {
                navigation.setBlockNavigation(undefined);
                setLastClicked(index);
                props.onSelect(option);
                (event.target as HTMLDivElement).scrollIntoView({
                    behavior: 'smooth',
                    block: 'end',
                    inline: 'end'
                });
		} else if(props.paramName) {
            navigation.setDestinationUrl(navigation.getParamUrl(props.paramName, index.toString()))
        }
    }

    return (
        <StyledSubNavigationContainer light={props.light ?? false} marginTop={props.marginTop}>
            {props.options.map((option, index) => {
                return <StyledSubNavigationButton light={props.light ?? false} active={selected === index} key={index} onClick={onSelect.bind(null, option, index)}>{option.name}</StyledSubNavigationButton>
            })}
        </StyledSubNavigationContainer>
    );
};

export default SubNavigation;