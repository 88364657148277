import React, {useContext, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useHistory} from 'react-router-dom';
import Pane from '../../../../../components/pane/pane.component';
import useNavigation from '../../../../../services/navigation.service';
import AvatarImage from '../../../../../assets/images/avatar.svg';
import {log} from "../../../../../services/firebaseAnalytics.service";
import { StyledInvitation } from '../planningTab.style';
import {IonCol, IonGrid, IonList, IonRow, useIonViewWillEnter} from '@ionic/react';
import moment from 'moment';
import {StyledGrayButton, StyledLightRedButton, StyledRedButton} from '../../../../../components/button/button.style';
import {Proposal, ProposalUser} from "../../../../../models/proposal";
import {getProposals, removeProposal, removeUserProposal} from "../../../../../services/proposal.service";
import StaticPane from "../../../../../components/pane/static-pane.component";
import {StyledEmptyContainer} from "../../../../../components/content/content.style";
import CircleImage, {ImageTypes} from "../../../../../components/circleImage/circleImage.component";
import InvitationSkeleton from "../components/invitationSkeleton.component";
import RemoveInvitationModal from "../modals/removeInvitation.component";
import RemovePeopleModal from "../modals/removePeople.component";
import WorkerPreviewModal from "../../../../../modals/worker/workerPreview.component";
import {StyledTaskDate} from "../../../../timetablePage/components/taskItem/taskItem.style";

type InvitationsPaneProps = {
    topEdge?: number;
}

interface PlanningManager {
    setInvitations: Function;
}

const contextPlanning: PlanningManager = {
    setInvitations: () => {

    }
};

export const PlanningContext = React.createContext<PlanningManager>(contextPlanning);

const InvitationsPane: React.FC<InvitationsPaneProps> = (props: InvitationsPaneProps) => {
    const {t} = useTranslation();
    const history = useHistory();
    const navigation = useNavigation(history);

    const [proposals, updateProposals] = useState<Proposal[] | undefined>(undefined);
    const [selectedProposal, updateSelectedProposal] = useState<Proposal | null>();
    const [loading, setLoading] = useState<boolean>(false);
    const [showRemoveModal, updateShowRemoveModal] = useState<boolean>(false);
    const [showUserRemoveModal, updateShowUserRemoveModal] = useState<boolean>(false);
    const [selectedWorker, setSelectedWorker] = useState<ProposalUser | null>(null);

    useEffect(() => {
        fetchProposals();
    });

    useIonViewWillEnter(() => {
        fetchProposals();
    });

    const fetchProposals = async () => {
        if (proposals === undefined && !loading) {
            setLoading(true);
            updateProposals([]);
            const response = await getProposals()
                .then(response => {
                    updateProposals(response.data);
                });

            setLoading(false);
        }
    };

	useEffect(() => {
		log('page_visit', {
			page: 'Planowanie -> Wysłane Zaproszenia'
		});
	}, []);

    const contextPlanning = useContext(PlanningContext);
    contextPlanning.setInvitations = updateProposals;

	return (<PlanningContext.Provider value={contextPlanning}>
        {(!loading && (proposals !== undefined && proposals.length === 0)) &&
                <StaticPane topEdge={props.topEdge} marginTop={35} paddingBottom={97}>
                    <StyledEmptyContainer>
                        <CircleImage image={ImageTypes.PLANNING} color="grey"/>
                    </StyledEmptyContainer>
                </StaticPane>
        }

        {loading &&
        <StaticPane topEdge={props.topEdge} marginTop={35} paddingBottom={97}>
            <IonList>
                <InvitationSkeleton />
                <InvitationSkeleton />
                <InvitationSkeleton />
                <InvitationSkeleton />
                <InvitationSkeleton />
                <InvitationSkeleton />
                <InvitationSkeleton />
            </IonList>
        </StaticPane>
        }

        {!loading && proposals && proposals.length > 0 &&
        <Pane topEdge={props.topEdge} marginTop={40} paddingBottom={147}>
            { proposals && proposals.map( (proposal, key) => <StyledInvitation key={key}>
                <IonGrid>
                    <IonRow>
                        <IonCol size="12" class="invitation-title">
							{proposal.job_position && proposal.job_position.length > 0 ? proposal.job_position : t("planningTab.invitationsPane.invitation") + ' #' + (proposals.length - key) } {/*#{ proposals.length - key}*/}
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol size="5">
                            <IonGrid>
                                <IonRow>
                                    <IonCol size="5">
                                        <StyledTaskDate>
                                            <span className="day">{moment(proposal.start_date).format('DD')}</span>
                                            <span className="month">{  moment(proposal.start_date).format('MMM') }</span>
                                        </StyledTaskDate>
                                    </IonCol>
                                    <IonCol size="0.5">
                                        <span className="separator">-</span>
                                    </IonCol>
                                    <IonCol size="5">
                                        <StyledTaskDate>
                                            <span className="day">{moment(proposal.end_date).format('DD')}</span>
                                            <span className="month">{  moment(proposal.end_date).format('MMM') }</span>
                                        </StyledTaskDate>
                                    </IonCol>
                                </IonRow>
                            </IonGrid>
                        </IonCol>
                        <IonCol size="7" class="content">
                            <div className="place">
                                { proposal.place_of_services?.name }
                            </div>
                            <div className="contractor">
                                { proposal.project?.name }
                            </div>
                        </IonCol>
                    </IonRow>
                    { proposal.accepted_users && proposal.accepted_users.length > 0 &&
                        <>
                            <IonRow>
                                <IonCol size="12">
                                    <div className="status-title border">
                                        { t("planningTab.invitationsPane.accepted") } ({proposal.accepted_users.length})
                                    </div>
                                </IonCol>
                            </IonRow>
                            <IonRow>
                                <IonCol size="12">
                                    <div className="members">
                                        {
                                            proposal.accepted_users.map((person, i) =>
                                                <img onClick={(e) => {
                                                    e.stopPropagation();
                                                    setSelectedWorker(person);
                                                }} src={person.user && (person.user?.photos || person.user?.avatar) ? (person.user.photos?.thumbnail ? person.user.photos.thumbnail : person.user.avatar) : AvatarImage} key={i}/>
                                            )
                                        }
                                    </div>
                                </IonCol>
                            </IonRow>
                        </>
                    }
                    { proposal.proposed_users && proposal.proposed_users.length > 0 &&
                        <>
                            <IonRow>
                                <IonCol size="12">
                                    <div className="status-title">
                                        { t("planningTab.invitationsPane.waiting") } ({proposal.proposed_users.length})
                                    </div>
                                </IonCol>
                            </IonRow>
                            <IonRow>
                                <IonCol size="12">
                                    <div className="members">
                                        {
                                            proposal.proposed_users.map((person, i) =>
                                                <img onClick={(e) => {
                                                    e.stopPropagation();
                                                    setSelectedWorker(person);
                                                }} src={person.user && (person.user?.photos || person.user?.avatar) ? (person.user.photos?.thumbnail ? person.user.photos.thumbnail : person.user.avatar) : AvatarImage} key={i}/>
                                            )
                                        }
                                    </div>
                                </IonCol>
                            </IonRow>
                        </>
                    }
					{ proposal.rejected_users && proposal.rejected_users.length > 0 &&
						<>
							<IonRow>
								<IonCol size="12">
									<div className="status-title">
										{ t("planningTab.invitationsPane.declined") } ({proposal.rejected_users.length})
									</div>
								</IonCol>
							</IonRow>
							<IonRow>
								<IonCol size="12">
									<div className="members">
										{
											proposal.rejected_users.map((person, i) =>
												<img onClick={(e) => {
													e.stopPropagation();
													setSelectedWorker(person);
												}} src={person.user && (person.user?.photos || person.user?.avatar) ? (person.user.photos?.thumbnail ? person.user.photos.thumbnail : person.user.avatar) : AvatarImage} key={i}/>
											)
										}
									</div>
								</IonCol>
							</IonRow>
						</>
					}
                    <IonRow>
                        <IonCol size="6">
                            <StyledGrayButton onClick={() => {
                                updateSelectedProposal(proposal);
                                updateShowUserRemoveModal(true);
                            }}>
								{t('planningTab.invitationsPane.editButton')}
                            </StyledGrayButton>
                        </IonCol>

                        <IonCol size="6">
                            <StyledLightRedButton onClick={() => {
                                updateSelectedProposal(proposal);
                                updateShowRemoveModal(true);
                            }}>
								{t('planningTab.invitationsPane.removeButton')}
                            </StyledLightRedButton>
                        </IonCol>
                    </IonRow>
                </IonGrid>
            </StyledInvitation>
            )}
        </Pane>
        }
        <RemoveInvitationModal isOpen={showRemoveModal} proposal={selectedProposal} onClose={() => {
            updateSelectedProposal(null);
            updateShowRemoveModal(false);
        }} onRemove={async (comment: string) => {
            if (selectedProposal) {
                await removeProposal(selectedProposal.id, comment);
                await getProposals()
                    .then(response => {
                        updateProposals(response.data);
                        updateSelectedProposal(null);
                        updateShowRemoveModal(false);
                    });
            }

        }} />
        <RemovePeopleModal isOpen={showUserRemoveModal} proposal={selectedProposal} onClose={() => {
            updateSelectedProposal(null);
            updateShowUserRemoveModal(false);
        }} onRemove={async (user: ProposalUser, comment: string) => {
            await removeUserProposal(user.id, comment);
            await getProposals()
                .then(response => {
                    updateProposals(response.data);
                    updateSelectedProposal(null);
                    updateShowUserRemoveModal(false);
                });
        }} />
            <WorkerPreviewModal worker={selectedWorker} isOpen={!!selectedWorker} onClose={() => setSelectedWorker(null)} />
        </PlanningContext.Provider>
    );
};

export default InvitationsPane;
