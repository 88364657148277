import React, {useEffect, useRef, useState} from "react";
import {Proposal, ProposalUser} from "../../../../../models/proposal";
import Modal from "../../../../../components/modal/modal.component";
import {StyledModalContent} from "../../../../../components/modal/modal.style";
import {useTranslation} from "react-i18next";
import AvatarImage from "../../../../../assets/images/avatar-blue.svg";
import TrashImage from "../../../../../assets/images/trash-can.svg";
import {StyledDataTable} from "../../../../../components/content/content.style";
import {StyledTextarea} from "../../../../../components/form/input/textarea.style";
import {StyledFooter} from "../../../../../components/pane/pane.style";
import {
    StyledRedButton,
    StyledRedIconButton
} from "../../../../../components/button/button.style";
import {IonButtons, IonIcon} from "@ionic/react";
import { arrowBack } from 'ionicons/icons';
import {StyledContent} from "../../../../reportPage/choosePersonPage.style";
import {disableSwipeToClose} from "../../../../../utils/tools/modals";

type RemovePeopleModalProps = {
    isOpen: boolean;
    proposal?: Proposal | null;
    onClose: () => void;
    onRemove: (user: ProposalUser, comment: string) => void;
};

const RemovePeopleModal: React.FC<RemovePeopleModalProps> = (props: RemovePeopleModalProps) => {

    const {t} = useTranslation();
    const [show, updateShow] = useState<boolean>(false);
    const commentRef = useRef<HTMLTextAreaElement>(null);
    const [comment, updateComment] = useState<string>('');
    const [selectedUser, updateSelectedUser] = useState<ProposalUser | null>();

    const handleClose = () => {
        updateComment('');
        updateSelectedUser(null);
        props.onClose();
    };

    useEffect(() => {
        handleOpen();
    });

    const handleOpen = () => {
        updateShow(props.isOpen);
    };

    const handleSave = async () => {
        if (selectedUser) {
            props.onRemove(selectedUser, comment);
        }
    }

    const handleSelectUser = (user: ProposalUser) => {
        updateSelectedUser(user);
    }

    const handleCommentChange = (event: any) => {
        updateComment(event.target.value);
    };

    return (
        <Modal
            isOpen={show}
            canDismiss={disableSwipeToClose}
            maxHeight={'95%'}
            onDidDismiss={() => handleClose()}>
            {!selectedUser &&
                <StyledModalContent>
                    <h1 className="title">{t('planningTab.removePeopleInvitation.title')}</h1>
                    <div className="content full-height">
                        <StyledDataTable className="no-margin">
                            {props.proposal && props.proposal.accepted_users && props.proposal.accepted_users.map((data, i) =>
                                <div key={i} className="data-row">
                                    <div className="data-icon-title"><img className="avatar"
                                                                          src={data.user.photos?.thumbnail ? data.user.photos.thumbnail : AvatarImage}/>{data.name}
                                    </div>
                                    <div className="data-action"><StyledRedIconButton
                                        onClick={() => handleSelectUser(data)}><img
                                        src={TrashImage}/></StyledRedIconButton></div>
                                </div>)
                            }
                            {props.proposal && props.proposal.proposed_users && props.proposal.proposed_users.map((data, i) =>
                                <div key={i} className="data-row">
                                    <div className="data-icon-title"><img className="avatar"
                                                                          src={data.user && (data.user?.photos || data.user?.avatar) ? (data.user.photos?.thumbnail ? data.user.photos.thumbnail : data.user.avatar) : AvatarImage}/>{data.name}
                                    </div>
                                    <div className="data-action"><StyledRedIconButton
                                        onClick={() => handleSelectUser(data)}><img
                                        src={TrashImage}/></StyledRedIconButton></div>
                                </div>)
                            }
                        </StyledDataTable>
                    </div>
                </StyledModalContent>
            }

            { selectedUser &&
                <>
                    <IonButtons onClick={() => updateSelectedUser(null)}>
                        <IonIcon slot="icon-only" icon={arrowBack} />
                    </IonButtons>
                    <h1 className="title">{t("planningTab.removePeopleInvitation.comment")} {selectedUser.name}</h1>
                    <StyledContent className="content">
                        <StyledTextarea className="full-width" ref={commentRef} onChange={handleCommentChange}></StyledTextarea>
                    </StyledContent>
                    <StyledFooter className="modal">
                        <StyledRedButton
                            onClick={() => handleSave()}
                            disabled={!comment}>{t("planningTab.removePeopleInvitation.save")}</StyledRedButton>
                    </StyledFooter>
                </>
            }
        </Modal>
    );
};

export default RemovePeopleModal;