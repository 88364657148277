import {appApi} from "./api.service";
import {
    Defect,
    DefectGroup,
    Order,
    Report,
    ReportRow,
    ReportRowComponent,
    ReportRowMeasurement,
    ReportRowWorker
} from "../app/reportPage/reportPage.component";
import {Preferences} from "@capacitor/preferences";
import {Filter, FilterParam, FilterType} from "../app/reportPage/components/filter/filterActionSheet.component";
import {Profile} from "../models/profile";
import {ProfileType} from "../enums/profileType";
import {ReportSetting} from "../enums/report";
import {getDefaultOffset, getNumberOfWorkDays, nextMoment, prevMoment} from "../utils/tools/time";
import {getTimepickerMinutesIterations} from "../utils/tools/timepicker";
import {TimepickerMinutesIterationsType} from "../enums/timepickerMinutesIterationsType";
import {PricingType} from "../enums/pricing";

const MomentRange = require('moment-range');
const Moment = require('moment-timezone')
const moment = MomentRange.extendMoment(Moment);
moment.tz.setDefault('Europe/Warsaw');
const momentDurationFormatSetup = require("moment-duration-format");
momentDurationFormatSetup(moment);

let endpointPrefix = 'reports';

const getReports = async (status: string, params: any) => {
    let profileId = await Preferences.get({'key': 'profile_id'});

    return appApi.get(endpointPrefix + '/list/' + status + '?profileId=' + profileId.value + '&' + params);
}

const getReport = async (reportId: number | string) => {
    let profileId = await Preferences.get({'key': 'profile_id'});
    return appApi.get(endpointPrefix + '/' + reportId + '/view?profileId=' + profileId.value);
}

const checkSpecification = async (id: bigint | string, reportId = '', skipDetailedCheck: boolean = false) => {
    let profileId = await Preferences.get({'key': 'profile_id'});
    let allowNotNewest = 0;
    if (reportId && parseInt(reportId) !== 0) {
        allowNotNewest = 1;
    }
    return appApi.get(endpointPrefix + '/check-specification/' + id + '?allowNotNewest=' + allowNotNewest + '&profileId=' + profileId.value + '&skipDetailedCheck=' + (skipDetailedCheck ? '1' : '0'));
}

const findSpecificationByNumber = async (number: bigint | string) => {
    let profileId = await Preferences.get({'key': 'profile_id'});
    return appApi.get(endpointPrefix + '/find-specification/' + number + '?profileId=' + profileId.value);
}

const findOrderByNumber = async (number: bigint | string, skipDetailedCheck: boolean = false) => {
    let profileId = await Preferences.get({'key': 'profile_id'});
    return appApi.get(endpointPrefix + '/check-order?orderNumber=' + number + '&profileId=' + profileId.value + '&skipDetailedCheck=' + (skipDetailedCheck ? '1' : '0'));
}

const findOrderById = async (id: bigint | string, skipDetailedCheck: boolean = false) => {
    let profileId = await Preferences.get({'key': 'profile_id'});
    return appApi.get(endpointPrefix + '/check-order?orderId=' + id + '&profileId=' + profileId.value + '&skipDetailedCheck=' + (skipDetailedCheck ? '1' : '0'));
}

const saveReport = async (order: Order, data: Report, reportId?: string, draft = false, skipLimits: boolean = false) => {
    if (reportId && parseInt(reportId) !== 0) {
        return editReport(reportId, data, draft, order, skipLimits);
    } else {
        return postReport(order.id, data, draft, order, skipLimits);
    }
}

const postReport = async (orderId: number | string, data: Report, draft = false, order: Order, skipLimits: boolean = false) => {
    let profileId = await Preferences.get({'key': 'profile_id'});
    return appApi.post(endpointPrefix + '/save-order-raport/' + orderId + '?profileId=' + profileId.value + '&draft=' + (draft ? 1 : 0) + '&skipLimits=' + (skipLimits ? 1 : 0), transformReportToApiFormat(data, order, profileId.value), {
        headers: {
            'No-Loader': true
        }
    });
}

const editReport = async (reportId: number | string, data: Report, draft = false, order: Order, skipLimits: boolean = false) => {
    let profileId = await Preferences.get({'key': 'profile_id'});
    return appApi.post(endpointPrefix + '/edit-order-raport/' + reportId + '?profileId=' + profileId.value + '&draft=' + (draft ? 1 : 0) + '&skipLimits=' + (skipLimits ? 1 : 0), transformReportToApiFormat(data, order, profileId.value), {
        headers: {
            'No-Loader': true
        }
    });
}

const transformReportToApiFormat = (data: Report, order: Order, profileId: string | number | null) => {
    return {
        order_report: {
            serviceDate: moment(data.serviceDate, moment.ISO_8601).utcOffset(getDefaultOffset(), false).format('YYYY-MM-DD'),
            ep2ScannedSpecificationId: data.specificationId,
            rows: data.rows.map((row) => {
                return {
                    serviceDate: moment(data.serviceDate, moment.ISO_8601).utcOffset(getDefaultOffset(), false).format('YYYY-MM-DD'),
                    deliveryDate: row.deliveryDate ? moment(row.deliveryDate, moment.ISO_8601).utcOffset(getDefaultOffset(), false).format('YYYY-MM-DD') : null,
                    checkedParts: row.quantity,
                    componentNumber: row.component ? row.component.id : null,
                    comment: row.comments.comment,
                    additionalInfo: row.comments.partNumber,
                    additionalInfo2: row.comments.additionalInfoA,
                    additionalInfo3: row.comments.additionalInfoB,
                    defectGroups: !order.is_extended_reporting ? row.defectGroups.map((defectGroup) => {
                        let defectiveParts = defectGroup.quantity;
                        if (order.sub_type === 'OPTIMIZATION' && defectGroup.notRepaired !== undefined && defectGroup.repaired !== undefined) {
                            defectiveParts = defectGroup.notRepaired + defectGroup.repaired;
                        }

                        return {
                            defectiveParts: defectiveParts,
                            notRepairedParts: defectGroup.notRepaired,
                            repairedParts: defectGroup.repaired,
                            defects: defectGroup.defects?.map((defect) => {
                                return {
                                    defect: !defect.other ? defect.id : null,
                                    other: defect.other
                                };
                            })
                        }
                    }) : [],
                    files: row.files.map((file) => {
                        return {
                            path: file.path,
                            originalName: file.original_name,
                            hash: file.hash,
                            extension: file.extension,
                            mimeType: file.mime_type,
                            size: file.size,
                        }
                    }),
                    components: order.is_extended_reporting ? row.components?.map((component: ReportRowComponent) => {
                        return {
                            comment: component.comment,
                            componentNumberText: component.componentNumberText,
                            checkedParts: component.quantity,
                            deliveryNote: component.deliveryNote,
                            levNumber: component.levNumber,
                            serialNumber: component.serialNumber,
                            batchNumber: component.batchNumber,
                            files: component.files.map((file) => {
                                return {
                                    path: file.path,
                                    originalName: file.original_name,
                                    hash: file.hash,
                                    extension: file.extension,
                                    mimeType: file.mime_type,
                                    size: file.size,
                                }
                            }),
                            defectGroups: component.defectGroups.map((defectGroup) => {
                                let defectiveParts = defectGroup.quantity;
                                if (order.sub_type === 'OPTIMIZATION' && defectGroup.notRepaired !== undefined && defectGroup.repaired !== undefined) {
                                    defectiveParts = defectGroup.notRepaired + defectGroup.repaired;
                                }

                                return {
                                    defectiveParts: defectiveParts,
                                    notRepairedParts: defectGroup.notRepaired,
                                    repairedParts: defectGroup.repaired,
                                    defects: defectGroup.defects?.map((defect) => {
                                        return {
                                            defect: !defect.other ? defect.id : null,
                                            other: defect.other
                                        }
                                    })
                                }
                            }),
                        };
                    }) : undefined,
                    measurements: row.measurements?.map((measurement) => {
                        return {
                            measuringEquipmentItemCategory: measurement.measuring_equipment_item_category?.id,
                            measuringEquipmentOrderItem: !measurement.hide ? measurement.measuring_equipment_order_item?.id : null,
                            multiplier: measurement.multiplier,
                            hide: measurement.hide,
                            missing: !measurement.hide ? measurement.missing : false,
                            description: !measurement.hide ? measurement.description : null
                        }
                    }),
                    workers: row.workers.map((worker) => {
                        return {
                            worker: worker.personId,
                            startAt: moment(worker.from, moment.ISO_8601).utcOffset(getDefaultOffset(), false).format('HH:mm'),
                            endAt: worker.to ? moment(worker.to, moment.ISO_8601).utcOffset(getDefaultOffset(), false).format('HH:mm') : null,
                            globalPricingItem: profileId ? worker.pricingRate?.id : null,
                            rate: profileId ? worker.rate?.id : null,
                            workerOvertime: profileId ? worker.workerOvertime : null,
                            clientOvertime: profileId ? worker.clientOvertime : null,
                        }
                    })
                }
            })
        }
    }
}

const transformApiFormatToReport = (data: any) => {
    let rows: ReportRow[] = [];
    for (let r of data.rows) {
        let workers: ReportRowWorker[] = [];
        for (let w of r.workers) {
            let worker = {
                avatarUrl: w.worker.user?.photos?.thumbnail ? w.worker.user?.photos?.thumbnail : (w.worker.user?.avatar ? w.worker.user?.avatar : undefined),
                personId: w.worker.id,
                name: w.worker.name,
                number: w.worker.number,
                from: moment(w.start_at, moment.ISO_8601).tz('Europe/Warsaw').format(),
                to: w.end_at ? moment(w.end_at, moment.ISO_8601).tz('Europe/Warsaw').format() : undefined,
                hoursHumanized: hoursHumanized(moment(w.start_at, moment.ISO_8601).tz('Europe/Warsaw').format('HH:mm'), moment(w.end_at, moment.ISO_8601).tz('Europe/Warsaw').format('HH:mm')),
                pricingRate: w.global_pricing_item,
                rate: w.rate,
                clientOvertime: w.client_overtime,
                workerOvertime: w.worker_overtime,
            };

            workers = [...workers, worker];
        }

        let rowDefects: DefectGroup[] = [];
        for (let dg of r.defect_groups) {
            let defects: Defect[] = [];
            for (let d of dg.defects) {
                let defect = {
                    id: !d.other ? d.defect.id : d.other,
                    name: !d.other ? d.defect.name : d.other,
                    other: d.other,
                    defect: d.defect,
                };

                defects = [...defects, defect];
            }

            let defectGroup = {
                quantity: dg.defective_parts,
                repaired: dg.repaired_parts,
                notRepaired: dg.not_repaired_parts,
                defects: defects,
            };
            rowDefects = [...rowDefects, defectGroup];
        }

        let components: ReportRowComponent[] = [];
        for (let c of r.components) {
            let componentDefects: DefectGroup[] = [];
            for (let dg of c.defect_groups) {
                let defects: Defect[] = [];
                for (let d of dg.defects) {
                    let defect = {
                        id: !d.other ? d.defect.id : d.other,
                        name: !d.other ? d.defect.name : d.other,
                        other: d.other,
                        defect: d.defect,
                    };

                    defects = [...defects, defect];
                }

                let defectGroup = {
                    quantity: dg.defective_parts,
                    repaired: dg.repaired_parts,
                    notRepaired: dg.not_repaired_parts,
                    defects: defects,
                };
                componentDefects = [...componentDefects, defectGroup];
            }

            let component = {
                defectGroups: componentDefects,
                files: c.files,
                comment: c.comment,
                componentNumberText: c.component_number_text,
                quantity: c.checked_parts,
                deliveryNote: c.delivery_note,
                levNumber: c.lev_number,
                serialNumber: c.serial_number,
                batchNumber: c.batch_number,
                id: Math.random().toString(),
            } as ReportRowComponent;

            components = [...components, component];
        }

        let measurements: ReportRowMeasurement[] = [];
        for (let m of r.measurements) {
            let measurement = {
                measuring_equipment_item_category: m.measuring_equipment_item_category,
                measuring_equipment_order_item: m.measuring_equipment_order_item,
                multiplier: m.multiplier,
                missing: m.missing,
                hide: m.hide,
                description: m.description
            };

            measurements = [...measurements, measurement]
        }

        let row = {
            removed: false,
            components: components,
            component: {
                id: r.component_number ? r.component_number.id : null,
                name: r.component_number ? r.component_number.number : null
            },
            quantity: r.checked_parts,
            defectGroups: rowDefects,
            files: r.files,
            measurements: measurements,
            comments: {
                comment: r.comment,
                partNumber: r.additional_info,
                additionalInfoA: r.additional_info2,
                additionalInfoB: r.additional_info3
            },
            deliveryDate: r.delivery_date ? moment(r.delivery_date, moment.ISO_8601).tz('Europe/Warsaw').format() : null,
            workers: workers
        };
        rows = [...rows, row];
    }

    return {
        orderId: data.order.id,
        reportId: data.id,
        orderNumber: data.order.formatted_number.replace('-', '/'),
        orderFactoryNumber: data.order.factory_number ? data.order.factory_number.replace('-', '/') : null,
        serviceDate: moment(data.service_date, moment.ISO_8601).tz('Europe/Warsaw').format(),
        specificationId: data.ep2_scanned_specification_id ? data.ep2_scanned_specification_id : (data.order.specification ? data.order.specification.id : null),
        rows: rows,
        status: data.status
    }
}

const validateReport = (data: Report, order: Order, profile?: Profile, withoutSpecification: boolean = false) => {
    if (data.rows.length === 0) {
        return false;
    }

    for (let row of data.rows) {
        if (row.workers.length === 0) {
            return false;
        }

        if (order.specification?.hasMeasurements && !row.skipMeasurements && (!row.measurements || !validateMeasurements(row.measurements))) {
            return false;
        }

        if (order.is_extended_reporting) {
            if (!withoutSpecification) {
                if (!row.component.id) {
                    return false;
                }
            }

            if (row.components?.length === 0) {
                return false;
            }
            if (row.components) {
                for (let component of row.components) {
                    if (!withoutSpecification) {
                        if (!component.componentNumberText) {
                            return false;
                        }

                        if (isSettingEnabled(ReportSetting.SERIAL_NUMBER, order) && !component.serialNumber && isSettingEnabled(ReportSetting.SERIAL_NUMBER_REQUIRED, order)) {
                            return false;
                        }

                        if (isSettingEnabled(ReportSetting.LEV_NUMBER, order) && !component.levNumber && isSettingEnabled(ReportSetting.LEV_NUMBER_REQUIRED, order)) {
                            return false;
                        }

                        if (isSettingEnabled(ReportSetting.DELIVERY_NOTE, order) && !component.deliveryNote && isSettingEnabled(ReportSetting.DELIVERY_NOTE_REQUIRED, order)) {
                            return false;
                        }

                        if (isSettingEnabled(ReportSetting.BATCH_NUMBER, order) && !component.batchNumber && isSettingEnabled(ReportSetting.BATCH_NUMBER_REQUIRED, order)) {
                            return false;
                        }

                        if (component.componentNumberText && order.nested_reporting_components.length && !order.nested_reporting_components.includes(component.componentNumberText)) {
                            return false;
                        }

                        if (!component.quantity && component.quantity !== 0) {
                            return false;
                        }
                    }

                    let sum = 0;
                    for (let defectGroup of component.defectGroups) {
                        if (defectGroup.defects) {
                            if (defectGroup.defects.length === 0) {
                                return false;
                            }

                            for (let defect of defectGroup.defects) {
                                if (!defect.id) {
                                    return false;
                                }
                            }
                        }

                        if (order.sub_type === 'OPTIMIZATION') {
                            if (!defectGroup.notRepaired && defectGroup.notRepaired !== 0) {
                                return false;
                            }

                            if (!defectGroup.repaired && defectGroup.repaired !== 0) {
                                return false;
                            }

                            sum += (defectGroup.notRepaired + defectGroup.repaired);
                        } else {
                            if (!defectGroup.quantity && defectGroup.quantity !== 0) {
                                return false;
                            }

                            sum += defectGroup.quantity;
                        }
                    }

                    if (component.quantity && sum > 0 && sum > component.quantity) {
                        return false;
                    }
                }
            }
        } else {
            if (!withoutSpecification) {
                if (!row.component.id) {
                    return false;
                }

                if (!row.quantity && row.quantity !== 0) {
                    return false;
                }
            }

            let sum = 0;
            for (let defectGroup of row.defectGroups) {
                if (defectGroup.defects) {
                    if (defectGroup.defects.length === 0) {
                        return false;
                    }

                    for (let defect of defectGroup.defects) {
                        if (!defect.id) {
                            return false;
                        }
                    }
                }

                if (order.sub_type === 'OPTIMIZATION') {
                    if (!defectGroup.notRepaired && defectGroup.notRepaired !== 0) {
                        return false;
                    }

                    if (!defectGroup.repaired && defectGroup.repaired !== 0) {
                        return false;
                    }

                    sum += (defectGroup.notRepaired + defectGroup.repaired);
                } else {
                    if (!defectGroup.quantity && defectGroup.quantity !== 0) {
                        return false;
                    }

                    sum += defectGroup.quantity;
                }
            }

            if (row.quantity && sum > 0 && sum > row.quantity) {
                return false;
            }
        }

        for (let worker of row.workers) {
            if (!worker.from) {
                return false;
            }

            let from = moment(worker.from, moment.ISO_8601).format('HH:mm')
            if (worker.to) {
                let to = moment(worker.to, moment.ISO_8601).format('HH:mm')

                if (from == to) {
                    return false;
                }
            }

            if (profile && profile.type === ProfileType.COORDINATOR) {
                if (![PricingType.FIXED_PRICE, PricingType.FIXED_PRICE_PER_MONTH].includes(order.pricing_type) && !worker.pricingRate) {
                    return false;
                }

                if (!worker.rate) {
                    return false;
                }
            }

            if (!profile && validateHoursPassingMidnight(worker, data, order) !== true) {
                return false;
            }
        }
    }

    return true;
}

export const validateRowComponent = (component: ReportRowComponent, order: Order, withoutSpecification: boolean = false) => {
    if (!withoutSpecification) {
        if (!component.componentNumberText) {
            return false;
        }

        if (isSettingEnabled(ReportSetting.SERIAL_NUMBER, order) && !component.serialNumber && isSettingEnabled(ReportSetting.SERIAL_NUMBER_REQUIRED, order)) {
            return false;
        }

        if (isSettingEnabled(ReportSetting.LEV_NUMBER, order) && !component.levNumber && isSettingEnabled(ReportSetting.LEV_NUMBER_REQUIRED, order)) {
            return false;
        }

        if (isSettingEnabled(ReportSetting.DELIVERY_NOTE, order) && !component.deliveryNote && isSettingEnabled(ReportSetting.DELIVERY_NOTE_REQUIRED, order)) {
            return false;
        }

        if (isSettingEnabled(ReportSetting.BATCH_NUMBER, order) && !component.batchNumber && isSettingEnabled(ReportSetting.BATCH_NUMBER_REQUIRED, order)) {
            return false;
        }

        if (component.componentNumberText && order.nested_reporting_components.length && !order.nested_reporting_components.includes(component.componentNumberText)) {
            return false;
        }

        if (!component.quantity && component.quantity !== 0) {
            return false;
        }
    }

    let sum = 0;
    for (let defectGroup of component.defectGroups) {
        if (defectGroup.defects) {
            if (defectGroup.defects.length === 0) {
                return false;
            }

            for (let defect of defectGroup.defects) {
                if (!defect.id) {
                    return false;
                }
            }
        }

        if (order.sub_type === 'OPTIMIZATION') {
            if (!defectGroup.notRepaired && defectGroup.notRepaired !== 0) {
                return false;
            }

            if (!defectGroup.repaired && defectGroup.repaired !== 0) {
                return false;
            }

            sum += (defectGroup.notRepaired + defectGroup.repaired);
        } else {
            if (!defectGroup.quantity && defectGroup.quantity !== 0) {
                return false;
            }

            sum += defectGroup.quantity;
        }
    }

    if (component.quantity && sum > 0 && sum > component.quantity) {
        return false;
    }

    return true;
}

const isEmptyReport = (report: Report, withoutSpecification: boolean = false, allowNotEmptyQuantity: boolean = false) => {
    let empty = true;

    for (let row of report.rows) {
        if (!withoutSpecification) {
            if (row.workers.length > 0 || (!allowNotEmptyQuantity && row.quantity && row.quantity > 0) || (row.component && row.component.id) || row.defectGroups.length > 0) {
                empty = false;
            }
        } else {
            if (row.workers.length > 0) {
                empty = false;
            }
        }

        if (row.components) {
            for (let component of row.components) {
                if (component.defectGroups.length > 0) {
                    empty = false;
                }
            }
        }
    }

    return empty;
}

const validateReportRowQuantity = (order: Order, data: ReportRow) => {
    let sum = 0;
    if (order.is_extended_reporting) {
        return true;
    }

    for (let defectGroup of data.defectGroups) {

        if (order.sub_type === 'OPTIMIZATION') {
            if (!defectGroup.notRepaired && defectGroup.notRepaired !== 0) {
                return false;
            }

            if (!defectGroup.repaired && defectGroup.repaired !== 0) {
                return false;
            }

            sum += (defectGroup.notRepaired + defectGroup.repaired);
        } else {
            if (defectGroup.quantity) {
                sum += defectGroup.quantity;
            }
        }
    }

    return !(sum > 0 && data.quantity && sum > data.quantity);
}

export const validateReportRowComponentQuantity = (order: Order, data: ReportRowComponent) => {
    let sum = 0;
    for (let defectGroup of data.defectGroups) {

        if (order.sub_type === 'OPTIMIZATION') {
            if (!defectGroup.notRepaired && defectGroup.notRepaired !== 0) {
                return false;
            }

            if (!defectGroup.repaired && defectGroup.repaired !== 0) {
                return false;
            }

            sum += (defectGroup.notRepaired + defectGroup.repaired);
        } else {
            if (defectGroup.quantity) {
                sum += defectGroup.quantity;
            }
        }
    }

    return !(sum > 0 && data.quantity && sum > data.quantity);
}

export const validateMeasurements = (measurements: ReportRowMeasurement[]) => {
    let isValid = true;
    for (let measurement of measurements) {
        if (!measurement.hide) {
            if (isValid && ((!measurement.measuring_equipment_order_item && measurement.missing && !measurement.description)) || (!measurement.measuring_equipment_order_item && !measurement.missing)) {
                isValid = false;
            }
        }
    }

    return isValid;
}


export const canScanNextComponent = (order: Order, row: ReportRow, withoutSpecification: boolean = false) => {
    if (row.components) {
        for (let component of row.components) {
            if (!withoutSpecification) {
                if (!component.componentNumberText) {
                    return false;
                }

                if (isSettingEnabled(ReportSetting.SERIAL_NUMBER, order) && !component.serialNumber && isSettingEnabled(ReportSetting.SERIAL_NUMBER_REQUIRED, order)) {
                    return false;
                }

                if (isSettingEnabled(ReportSetting.LEV_NUMBER, order) && !component.levNumber && isSettingEnabled(ReportSetting.LEV_NUMBER_REQUIRED, order)) {
                    return false;
                }

                if (isSettingEnabled(ReportSetting.DELIVERY_NOTE, order) && !component.deliveryNote && isSettingEnabled(ReportSetting.DELIVERY_NOTE_REQUIRED, order)) {
                    return false;
                }

                if (isSettingEnabled(ReportSetting.BATCH_NUMBER, order) && !component.batchNumber && isSettingEnabled(ReportSetting.BATCH_NUMBER_REQUIRED, order)) {
                    return false;
                }

                if (!component.quantity && component.quantity !== 0) {
                    return false;
                }
            }

            let sum = 0;
            for (let defectGroup of component.defectGroups) {

                if (defectGroup.defects) {
                    if (defectGroup.defects.length === 0) {
                        return false;
                    }

                    for (let defect of defectGroup.defects) {
                        if (!defect.id) {
                            return false;
                        }
                    }
                }

                if (order.sub_type === 'OPTIMIZATION') {
                    if (!defectGroup.notRepaired && defectGroup.notRepaired !== 0) {
                        return false;
                    }

                    if (!defectGroup.repaired && defectGroup.repaired !== 0) {
                        return false;
                    }

                    sum += (defectGroup.notRepaired + defectGroup.repaired);
                } else {
                    if (!defectGroup.quantity && defectGroup.quantity !== 0) {
                        return false;
                    }

                    sum += defectGroup.quantity;
                }
            }

            if (component.quantity && sum > 0 && sum > component.quantity) {
                return false;
            }
        }
    }

    return true;
}

const validateTimeOverlap = (report: Report) => {
    let overlapped = <any>[];
    let all = [];
    for (let row of report.rows) {
        for (let worker of row.workers) {
            all.push(worker);
        }
    }

    let grouped = all.reduce(function (r, a) {
        if (a.personId && a.from && a.to) {
            r[a.personId] = r[a.personId] || [];
            r[a.personId].push(a);
        }
        return r;
    }, Object.create(null));

    for (const [key, value] of Object.entries(grouped)) {
        if (Array.isArray(value)) {
            var o = value.map((r: ReportRowWorker) => {
                return value.filter((q: ReportRowWorker) => q != r).map((q: ReportRowWorker) => {
                        if (moment.range(
                            moment(moment(q.from).format('YYYY-MM-DD HH:mm')),
                            moment(moment(q.to).format('YYYY-MM-DD HH:mm')),
                        ).overlaps(
                            moment.range(
                                moment(moment(r.from).format('YYYY-MM-DD HH:mm')),
                                moment(moment(r.to).format('YYYY-MM-DD HH:mm'))
                            ), {
                                adjacent: false
                            }
                        )) {
                            return r.name;
                        }
                    }
                )
            });

            for (const [key, value] of Object.entries(o)) {
                if (value[0] !== undefined) {
                    overlapped.push(value[0]);
                }
            }
        }
    }

    let u = overlapped.filter((c: any, index: any) => {
        return overlapped.indexOf(c) === index;
    });

    // unique
    return overlapped.filter((c: any, index: any) => {
        return overlapped.indexOf(c) === index;
    });
}

const orderComponentsNumbers = async (orderId: number | string) => {
    let profileId = await Preferences.get({'key': 'profile_id'});
    return appApi.get(endpointPrefix + '/components-number/' + orderId + '?profileId=' + profileId.value);
}

const orderDefects = async (specificationId: number | string) => {
    let profileId = await Preferences.get({'key': 'profile_id'});
    return appApi.get(endpointPrefix + '/defects/' + specificationId + '?profileId=' + profileId.value);
}

const orderWorkers = async (orderId: number | string, serviceDate: string, page: number = 1, searchText: string = '') => {
    let profileId = await Preferences.get({'key': 'profile_id'});
    return appApi.get(endpointPrefix + '/workers/' + orderId + '/' + serviceDate + '?profileId=' + profileId.value + '&page=' + page + '&searchText=' + searchText);
}

const checkWorker = async (orderId: number | string, number: string, serviceDate: string) => {
    let profileId = await Preferences.get({'key': 'profile_id'});
    return appApi.get(endpointPrefix + '/check-worker/' + orderId + '/' + number + '/' + serviceDate + '?profileId=' + profileId.value);
}

const getWorkerData = async (orderId: string, number: string, serviceDate: string) => {
    let workerData: any = await checkWorker(orderId, number, serviceDate)
        .then(response => {
            if (response.status === 200) {
                return {
                    id: response.data.id,
                    name: response.data.name,
                    number: response.data.number,
                    user: response.data.user
                };
            } else {
                return null;
            }
        }).catch(reason => {
            return null
        });

    return workerData;
}

const getPlaceOfServices = (profileId: string | number) => {
    return appApi.get(endpointPrefix + '/place-of-services?profileId=' + profileId);
}

const getPlaceOfServiceDepartments = (placeOfServicesId: number, profileId: string | number) => {
    return appApi.get(endpointPrefix + '/place-of-service/' + placeOfServicesId + '/departments?profileId=' + profileId);
}

const getReportsCount = (status: string, profileId: string | number, params: any = null) => {
    return appApi.get(endpointPrefix + '/counts/' + status + '?profileId=' + profileId + '&' + params);
}

const getNewestSpecification = async (orderId: string) => {
    let profileId = await Preferences.get({'key': 'profile_id'});
    return appApi.get(endpointPrefix + '/newest-specification/' + orderId + '?profileId=' + profileId.value);
}

const getPriceRates = async (orderId: number | string, reportId?: number | string | null, serviceData?: string) => {
    let profileId = await Preferences.get({'key': 'profile_id'});
    return appApi.get(endpointPrefix + '/price-rates/' + orderId + '?profileId=' + profileId.value + '&reportId=' + reportId + '&serviceDate=' + serviceData);
}

const getWorkerRates = async (orderId: number | string, number: string, serviceDate: string) => {
    let profileId = await Preferences.get({'key': 'profile_id'});
    return appApi.get(endpointPrefix + '/worker-rates/' + orderId + '/' + number + '/' + serviceDate + '?profileId=' + profileId.value);
}

export const getMeasuringEquipments = async (specificationId: number | string, serviceDate: string) => {
    let profileId = await Preferences.get({'key': 'profile_id'});
    return appApi.get(endpointPrefix + '/measuring-equipments/' + specificationId + '/' + serviceDate + '?profileId=' + profileId.value);
}

export const getMeasuringEquipmentsItems = async (specificationId: number | string, serviceDate: string, categoryId: number | string) => {
    let profileId = await Preferences.get({'key': 'profile_id'});
    return appApi.get(endpointPrefix + '/measuring-equipments-items/' + specificationId + '/' + serviceDate + '/' + categoryId + '?profileId=' + profileId.value);
}

const filtersToParamsArray = (filters: Filter[]) => {
    let params: string[] = [];
    filters.forEach((filter) => {
        switch (filter.type) {
            case FilterType.ORDER_NUMBER:
                params.push('orderNumber=' + encodeURIComponent(filter.value));
                break;

            case FilterType.COMPONENT_NUMBER:
                params.push('componentNumber=' + encodeURIComponent(filter.value));
                break;

            case FilterType.COMPONENT_NAME:
                params.push('componentName=' + encodeURIComponent(filter.value));
                break;

            case FilterType.PLACE_OF_SERVICE:
                params.push('placeOfService=' + encodeURIComponent(filter.value));
                break;

            case FilterType.PLACE_OF_SERVICE_DEPARTMENT:
                params.push('placeOfServiceDepartment=' + encodeURIComponent(filter.value));
                break;
        }
    });

    return params;
}

const getFilterTypeForParamName = (param: string) => {
    switch (param) {
        case 'orderNumber':
            return FilterType.ORDER_NUMBER;
        case 'componentNumber':
            return FilterType.COMPONENT_NUMBER;
        case 'componentName':
            return FilterType.COMPONENT_NAME;
        case 'placeOfService':
            return FilterType.PLACE_OF_SERVICE;
        case 'placeOfServiceDepartment':
            return FilterType.PLACE_OF_SERVICE_DEPARTMENT;
    }
}

const filtersToParamsObjects = (filters: Filter[]) => {
    let params: FilterParam[] = [];
    filters.forEach((filter) => {
        switch (filter.type) {
            case FilterType.ORDER_NUMBER:
                params.push({name: 'orderNumber', value: encodeURIComponent(filter.value)});
                break;

            case FilterType.COMPONENT_NAME:
                params.push({name: 'componentName', value: encodeURIComponent(filter.value)});
                break;

            case FilterType.COMPONENT_NUMBER:
                params.push({name: 'componentNumber', value: encodeURIComponent(filter.value)});
                break;

            case FilterType.PLACE_OF_SERVICE:
                params.push({name: 'placeOfService', value: encodeURIComponent(filter.value)});
                break;

            case FilterType.PLACE_OF_SERVICE_DEPARTMENT:
                params.push({name: 'placeOfServiceDepartment', value: encodeURIComponent(filter.value)});
                break;
        }
    });

    return params;
}

const filtersToParams = (filters: Filter[]) => {
    let params: string[] = filtersToParamsArray(filters);
    return params.join('&');
}

const hoursHumanized = (startAt: string, endAt: string) => {
    if (startAt > endAt) {
        startAt = moment().format('01-MM-YYYY') + ' ' + startAt;
        endAt = moment().format('02-MM-YYYY') + ' ' + endAt;
    } else {
        startAt = moment().format('01-MM-YYYY') + ' ' + startAt;
        endAt = moment().format('01-MM-YYYY') + ' ' + endAt;
    }

    let startAtMoment = moment(startAt, 'DD-MM-YYYY HH:mm'),
        endAtMoment = moment(endAt, 'DD-MM-YYYY HH:mm');

    let diff = moment.duration(endAtMoment.diff(startAtMoment));
    let hoursNumber = diff.asMinutes();
    if (hoursNumber > 0) {
        return diff.format("hh:mm", {
            trim: false
        })
    }
}

const minReportDate = (order: Order, report: Report, selectedProfile: Profile | undefined): string => {
    let serviceDate = moment(report.serviceDate, moment.ISO_8601).tz('Europe/Warsaw').format('YYYY-MM-DD');
    if (moment().format('YYYY-MM-DD') > moment(report.serviceDate, moment.ISO_8601).tz('Europe/Warsaw').format('YYYY-MM-DD')) {
        serviceDate = moment().tz('Europe/Warsaw').format('YYYY-MM-DD');
    }
    let daysBack = 0;
    if (selectedProfile && selectedProfile.type == ProfileType.COORDINATOR && order.days_back_coordinator) {
        daysBack = order.days_back_coordinator;
    } else if (selectedProfile && selectedProfile.type == ProfileType.TEAM_LEADER && order.days_back_team_leader) {
        daysBack = order.days_back_team_leader;
    }

    let returnDate = '';
    if (selectedProfile && (selectedProfile.type == ProfileType.COORDINATOR || selectedProfile.type == ProfileType.TEAM_LEADER) && report.reportId && report.status !== 'DRAFT') {
        returnDate = moment(report && report.serviceDate ? serviceDate + ' ' + moment().format('HH:mm:ss') : null).subtract(10, 'years').format('YYYY-MM-DD');
    } else if (order && (!report.reportId || report.reportId && report.status === 'DRAFT') && daysBack && (order.hours_for_select_prev_day && (order.hours_for_select_prev_day / 24) < daysBack) || !order.hours_for_select_prev_day) {
        returnDate = moment(report && report.serviceDate ? serviceDate + ' ' + moment().format('HH:mm:ss') : null).subtract(daysBack, 'days').format('YYYY-MM-DD');
    } else {
        returnDate = order && order.hours_for_select_prev_day ?
            (moment(report && report.serviceDate ? serviceDate + ' ' + moment().format('HH:mm:ss') : null).subtract(order.hours_for_select_prev_day, 'hours').hour() < 6 ?
                moment(report && report.serviceDate ? serviceDate + ' ' + moment().format('HH:mm:ss') : null).subtract(order.hours_for_select_prev_day, 'hours').subtract(1, 'day').format('YYYY-MM-DD')
                : moment(report && report.serviceDate ? serviceDate + ' ' + moment().format('HH:mm:ss') : null).subtract(order.hours_for_select_prev_day, 'hours').format('YYYY-MM-DD'))
            : moment(report && report.serviceDate ? serviceDate + ' ' + moment().format('HH:mm:ss') : null).format('YYYY-MM-DD');
    }


    return returnDate;
}

export const removeReport = async (reportId: number | string) => {
    let profileId = await Preferences.get({'key': 'profile_id'});
    return appApi.delete(endpointPrefix + '/' + reportId + '/remove?profileId=' + profileId.value);
}

export const isSettingEnabled = (check: ReportSetting, order: Order) => {
    if (order.enable_nested_reporting && order.nested_reporting_settings) {
        let setting = order.nested_reporting_settings;
        return setting.includes(check);
    } else if (order.enable_single_components && order.single_components_settings) {
        let setting = order.single_components_settings;
        return setting.includes(check);
    }
}

export const getNumberOfComponent = (row: ReportRow, component: ReportRowComponent) => {
    const tmp = row.components?.slice().reverse();
    return tmp ? tmp.indexOf(component) + 1 : null;
}

export const validateHoursPassingMidnight = (worker: ReportRowWorker, report: Report, order: Order) => {
    let workingDayEnd = order.working_day_end ? order.working_day_end : '0600';
    if (worker.from) {
        let from = moment(worker.from, moment.ISO_8601).format('HHmm');
        let fromObject = moment(moment(report.serviceDate, moment.ISO_8601).format('DD.MM.YYYY') + ' ' + moment(worker.from, moment.ISO_8601).format('HH:mm'), 'DD.MM.YYYY HH:mm');
        let workingDayEndDate = moment(moment(report.serviceDate, moment.ISO_8601).format('DD.MM.YYYY') + ' ' + workingDayEnd, 'DD.MM.YYYY HHmm');
        let now = moment();

        let tmp = workingDayEndDate.clone();
        tmp.add(1, 'day');
        workingDayEndDate = tmp;

        if (2400 !== parseInt(workingDayEnd) && from < parseInt(workingDayEnd)) {
            let tmp2 = fromObject.clone();
            tmp2.add(1, 'day');
            fromObject = tmp2;

            if (fromObject.isAfter(workingDayEndDate) || fromObject.isAfter(now)) {
                return fromObject;
            }
        } else {
            if (fromObject.isAfter(now)) {
                return fromObject;
            }
        }
    }

    return true;
}

export const validateQuarantinePeriod = (order: Order, serviceDate: string) => {
    if (!order || !order.days_for_quarantine) {
        return true;
    }

    return (getNumberOfWorkDays(moment(serviceDate).format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')) - 1) <= order.days_for_quarantine;
}

export const validateAvgTimePerComponent = (report: Report): boolean => {
    const checkedPartsSum = calculateCheckedPartsSum(report);
    const minutesSum = calculateControlMinutesSum(report);

    if (checkedPartsSum === 0) {
        return true;
    }

    return calculateAvgTimePerComponent(checkedPartsSum, minutesSum) > 0.01;
}

const calculateCheckedPartsSum = (report: Report): number => {
    let checkedPartsSum = 0;

    report.rows.forEach((row: ReportRow) => {
        if (row.quantity) {
            let quantity = typeof row.quantity === 'string' ? parseInt(row.quantity) : row.quantity;
            checkedPartsSum += quantity;
        }
    })

    return checkedPartsSum;
}

const calculateControlMinutesSum = (report: Report): number => {
    let minutesSum = 0;

    report.rows.forEach((row: ReportRow) => {
        row.workers?.forEach((worker: ReportRowWorker) => {
            if (worker.from && worker.to) {
                let fromDate = moment(worker.from);
                let toDate = moment(worker.to);
                let timeSpent = toDate.diff(fromDate, 'minutes') + 1;
                minutesSum += timeSpent;
            }
        })
    })

    return minutesSum;
}

const calculateAvgTimePerComponent = (checkedPartsSum: number, minutesSum: number): number => {
    if (minutesSum > 0 && checkedPartsSum > 0) {
        const avgTimePerComponent = (minutesSum * 60) / checkedPartsSum;

        return parseFloat(avgTimePerComponent.toFixed(2));
    }

    return 0;
}

const getReportTimepickerMinutesIterations = (order?: Order | null) => {
    if (order && order.use_time_iterations && order.reporting_iteration_type) {
        return getTimepickerMinutesIterations(order.reporting_iteration_type);
    }

    return getTimepickerMinutesIterations(TimepickerMinutesIterationsType.EVERY_5_MINUTES);
}

const getReportPrevMoment = (order?: Order | null) => {
    if (order && order.use_time_iterations && order.reporting_iteration_type) {
        switch (order.reporting_iteration_type) {
            case TimepickerMinutesIterationsType.EVERY_5_MINUTES:
                return prevMoment(5);
            case TimepickerMinutesIterationsType.EVERY_10_MINUTES:
                return prevMoment(10);
            case TimepickerMinutesIterationsType.EVERY_15_MINUTES:
                return prevMoment(15);
        }
    }

    return prevMoment();
}

const getReportNextMoment = (order?: Order | null) => {
    if (order && order.use_time_iterations && order.reporting_iteration_type) {
        switch (order.reporting_iteration_type) {
            case TimepickerMinutesIterationsType.EVERY_5_MINUTES:
                return nextMoment(5);
            case TimepickerMinutesIterationsType.EVERY_10_MINUTES:
                return nextMoment(10);
            case TimepickerMinutesIterationsType.EVERY_15_MINUTES:
                return nextMoment(15);
        }
    }

    return nextMoment();
}

export {
    getReports,
    checkSpecification,
    findSpecificationByNumber,
    saveReport,
    postReport,
    editReport,
    getReport,
    validateReport,
    validateReportRowQuantity,
    transformReportToApiFormat,
    transformApiFormatToReport,
    orderComponentsNumbers,
    orderDefects,
    orderWorkers,
    checkWorker,
    getWorkerData,
    getPlaceOfServices,
    getPlaceOfServiceDepartments,
    getReportsCount,
    filtersToParams,
    filtersToParamsArray,
    filtersToParamsObjects,
    getFilterTypeForParamName,
    getNewestSpecification,
    getWorkerRates,
    getPriceRates,
    findOrderByNumber,
    findOrderById,
    isEmptyReport,
    hoursHumanized,
    minReportDate,
    validateTimeOverlap,
    getReportTimepickerMinutesIterations,
    getReportPrevMoment,
    getReportNextMoment,
};
